import { NgModule, isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryState } from './summary/summary.state';
import { NgxsModule } from '@ngxs/store';
import { ProfileState } from './profile/profile.state';
import { ChallengesState } from './challenges/challenges.state';
import { HttpClientModule } from '@angular/common/http';
import { AwsResourcesState } from './aws-resources/aws-resources.state';
import { FiltersState } from "./filters/filters.state";
import { ExportsState } from "./exports/exports.state";
import { IacProjectsState } from './iac-projects/iac-projects.state';
import { CicdProjectsState } from './cicd-projects/cicd-projects.state';
import { TfModulesState } from './tfmodules/tfmodules.state';
import { CicdFactorisationsState } from './cicd-factorisations/cicd-factorisations.state';
import { VsphereResourcesState } from './vsphere-resources/vsphere-resources.state';
import { ImpervaResourcesState } from './imperva-resources/imperva-resources.state';
import { EntraIdResourcesState } from './entra-id-resources/entra-id-resources.state';
import { GcpResourcesState } from './gcp-resources/gcp-resources.state';
import { FinOpsContextState } from './finops-context/finops-context.state';
import { FinOpsAwsAccountsState } from './finops-aws-accounts/finops-aws-accounts.state';
import { FinOpsAwsServicesState } from './finops-aws-services/finops-aws-services.state';
import { FinOpsAwsResourcesState } from './finops-aws-resources/finops-aws-resources.state';
import { OnOffResourcesState } from './finops-aws-on-off/finops-aws-on-off.state';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { DevsecopsProjectsState } from './devsecops-projects/devsecops-projects.state';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    NgxsModule.forRoot([
      SummaryState,
      ProfileState,
      ChallengesState,
      AwsResourcesState,
      FiltersState,
      ExportsState,
      IacProjectsState,
      CicdProjectsState,
      TfModulesState,
      CicdFactorisationsState,
      VsphereResourcesState,
      ImpervaResourcesState,
      EntraIdResourcesState,
      GcpResourcesState,
      FinOpsContextState,
      FinOpsAwsAccountsState,
      FinOpsAwsServicesState,
      FinOpsAwsResourcesState,
      OnOffResourcesState,
      DevsecopsProjectsState
    ], {
      developmentMode: isDevMode(),
      selectorOptions: {} // empty object to test option merging
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      trace: isDevMode()
    }),
    NgxsLoggerPluginModule.forRoot({ disabled: !isDevMode() })
  ],
  exports: [
    NgxsModule
  ]
})
export class StoreModule { }

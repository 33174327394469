import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckComponent {

  @Input()
  title:string = ''

  @Input()
  checkContentText: any
  @Input()
  checkContentColor?: any

  @Input()
  checkLabelText?: any
  @Input()
  checkLabelColor?: any

  private _BASE_URL: string = 'https://img.shields.io/badge/'

  public constructCheckURL(checkContentText: string, checkContentColor?: string, checkLabelText?:string, checkLabelColor?: string) {

    let rightCheckPart=this.constructCheckContent(checkContentText, checkContentColor);
    let finalURL = this._BASE_URL;

    if(checkLabelText) {
      let leftCheckPart = this.constructLabelContent(checkLabelText, checkLabelColor);
      return finalURL.concat(rightCheckPart,'?',leftCheckPart)
    }
    return finalURL.concat(rightCheckPart);

  }

  private constructCheckContent(text: string, color?: string) {
    return color ?
        this.adaptValue(text).concat('-', color)
      : this.adaptValue(text).concat('-', 'gray') ;
  }

  private constructLabelContent(text?: string, color?: string) {

    const LABEL = 'label='
    const LABEL_COLOR = '&labelColor='
    return text ?
      (color ?
        LABEL.concat((text), LABEL_COLOR, color) : LABEL.concat((text)))
      : '' ;
  }

  private adaptValue(text: string): string {
    return text.replaceAll('-','--').replaceAll('_','__')
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iacRuntime'
})
export class IacRuntimePipe implements PipeTransform {

  transform(specificData: {}): string {
    let result = "";
    if (specificData !== undefined && 'runtime' in specificData) {
      result += specificData.runtime;
    }
    return result;
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "src/app/core/config.service";
import { FinOpsAwsResourceDetails, FinOpsAwsResourcesDayMeasures } from "./finops-aws-resources.model";
import { DateService, HistoricalDate } from "src/app/shared/service/date.service";
import { FinOpsContextConfig } from "../finops-context/finops-context.model";
import { DateGranularityEnum } from "src/app/shared/enum/date-granularity.enum";
import { forkJoin } from "rxjs";
import { AwsService } from "src/app/shared/service/aws.service";

@Injectable({
    providedIn: 'root'
})
export class FinOpsAwsResourcesService {

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigService,
        private dateService: DateService
    ) {
    }

    loadFinOpsAwsResourcesHistoricalData(config: FinOpsContextConfig, accountId: string) {
        let httpCalls = []
        let historicalDates: HistoricalDate[] = this.dateService.getHistoricalDates(config, 3)
        let accounts: string[] = []
        if (accountId === undefined) {
            accounts = AwsService.listAccounts()
        } else {
            accounts.push(accountId)
        }
        for (let account of accounts) {
            for (let historicalDate of historicalDates) {
                httpCalls.push(this.httpClient.get<FinOpsAwsResourcesDayMeasures>(`${this.configService.config.apiUrl}/list-finops-aws-resources-data?previous_date=${historicalDate.previousDate}&date=${historicalDate.date}&granularity=${DateGranularityEnum[config.granularity]}&account=${account}`))
            }
        }
        return forkJoin(httpCalls)
    }

    loadFinOpsAwsResourcesChartsData(config: FinOpsContextConfig, accountId: string) {
        let httpCalls = []
        let historicalDates: HistoricalDate[] = this.dateService.getHistoricalDates(config, config.depth)
        let accounts: string[] = []
        if (accountId === undefined) {
            accounts = AwsService.listAccounts()
        } else {
            accounts.push(accountId)
        }
        for (let account of accounts) {
            for (let historicalDate of historicalDates) {
                httpCalls.push(this.httpClient.get<FinOpsAwsResourcesDayMeasures>(`${this.configService.config.apiUrl}/list-finops-aws-resources-charts?date=${historicalDate.date}&account=${account}`))
            }
        }
        return forkJoin(httpCalls)
    }

    loadFinOpsAwsResourceDetails(date: string, resource: any) {
        return this.httpClient.get<FinOpsAwsResourceDetails>(`${this.configService.config.apiUrl}/get-finops-aws-resource-details?date=${date}&account=${resource.account}&resource_id=${resource.id}`)
    }
}

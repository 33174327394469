import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, StateToken, Store } from "@ngxs/store";
import { IacProjectsService } from "./iac-projects.service";
import {
    LoadIacProjects,
    LoadDataTableIacProjects,
    UpdateCountersIacProjects,
    ClearIacProject,
    SetIacProject,
    LoadIacProjectsCharts,
    LoadIacProjectsChartsFilteredData
} from "./iac-projects.actions";
import { tap } from "rxjs";
import { IacProjectsStateModel, IacProject } from "./iac-projects.model";
import { FiltersState } from "../filters/filters.state";
import { CompliantRatioService } from "src/app/shared/service/compliant-ratio.service";
import { IacBadgeService } from "src/app/shared/service/iac-badge.service";
import { IacProjectTypeEnum } from "src/app/shared/enum/iac-project-type.enum";
import { ChartComplianceRatioItem } from "src/app/shared/component/chart-compliance-ratio/chart-compliance-ratio.component";
import { ChartComplianceTotalItem } from "src/app/shared/component/chart-compliance-total/chart-compliance-total.component";
import { ChartVersusItem } from "src/app/shared/component/chart-versus/chart-versus.component";
import { DateService } from "src/app/shared/service/date.service";
import { IacProjectTypeLabelPipe } from "src/app/shared/pipe/iac-project-type-label.pipe";
import { ColorEnum } from "src/app/shared/enum/color.enum";
import { ItemTypeEnum } from "src/app/shared/enum/item-type.enum";

const IAC_TOKEN: StateToken<IacProjectsStateModel> = new StateToken('iacProjects');

const IacProjectsStateModelDefault: IacProjectsStateModel = {
    projects: [],
    compliantRatio: '0%',
    totalItems: '0',
    compliantItems: '0',
    notCompliantItems: '0',
    datatable: [],
    selectedProject: {
        age: 0,
        id: '',
        cfstacks: [],
        tfstates: [],
        resources: [],
        issues: [],
        name: '',
        source: '',
        state: 'ignored',
        allBadges: [],
        checked: [],
        unchecked: [],
        ignored: [],
        tfmodules: [],
        challengers: [],
        archived: false,
        type: '',
        url: ''
    },
    chartsData: {
        counters: {}
    },
    filteredComplianceRatioChartData: {
        values: []
    },
    filteredComplianceTotalChartData: {
        values: []
    },
    filteredVersusChartData: {
        values: []
    }
}

@State<IacProjectsStateModel>({
    name: IAC_TOKEN,
    defaults: IacProjectsStateModelDefault
})
@Injectable()
export class IacProjectsState {

    constructor(
        private iacProjectsService: IacProjectsService,
        private store: Store,
        private dateService: DateService,
        private iacProjectTypeLabelPipe: IacProjectTypeLabelPipe
    ) {
    }

    @Selector()
    static getProjects(state: IacProjectsStateModel) {
        return state.projects
    }

    @Selector()
    static getCompliantRatio(state: IacProjectsStateModel) {
        return state.compliantRatio
    }

    @Selector()
    static getTotalItems(state: IacProjectsStateModel) {
        return state.totalItems
    }

    @Selector()
    static getCompliantItems(state: IacProjectsStateModel) {
        return state.compliantItems
    }

    @Selector()
    static getNotCompliantItems(state: IacProjectsStateModel) {
        return state.notCompliantItems
    }

    @Selector()
    static getDatatable(state: IacProjectsStateModel) {
        return state.datatable
    }

    @Selector()
    static getSelectedProject(state: IacProjectsStateModel) {
        return state.selectedProject
    }

    @Selector()
    static getFilteredComplianceRatioChartData(state: IacProjectsStateModel) {
        return state.filteredComplianceRatioChartData
    }

    @Selector()
    static getFilteredComplianceTotalChartData(state: IacProjectsStateModel) {
        return state.filteredComplianceTotalChartData
    }

    @Selector()
    static getFilteredVersusChartData(state: IacProjectsStateModel) {
        return state.filteredVersusChartData
    }

    @Action(LoadIacProjects)
    loadIacProjects(ctx: StateContext<IacProjectsStateModel>) {
        return this.iacProjectsService.loadIacGitlabProjects()
            .pipe(
                tap(value => {
                    return ctx.patchState({
                        projects: value
                    })
                }),
            )
    }

    @Action(LoadDataTableIacProjects)
    loadDataTableIacProjects(ctx: StateContext<IacProjectsStateModel>) {
        const state = ctx.getState()
        let datatable = state.projects

        let filters = this.store.selectSnapshot(FiltersState.getFiltersByType)[ItemTypeEnum[ItemTypeEnum.IAC_PROJECTS]]
        filters.forEach(filter => {
            if (filter.key == 'issuesCount') {
                datatable = datatable.filter((project) => project['issues'].length.toString() === filter.value)
            } else if (filter.key == 'issuesType') {
                datatable = datatable.filter((project) => {
                    let issuesTypeFound = false
                    project.issues.forEach(issue => {
                        issuesTypeFound = issuesTypeFound || filter.value.includes(issue.issue_type)
                    })
                    return issuesTypeFound
                })
            } else if (filter.key == 'age') {
                let unknownAge = 99999
                let filterAge: number = unknownAge
                if (filter.value == 'today') {
                    filterAge = 0
                } else if (filter.value.startsWith('last_')) {
                    filterAge = filter.value.substring(5, 7)
                }
                datatable = datatable.filter((project) => {
                    let ageInRange = false
                    if (filterAge == unknownAge) {
                        ageInRange = project.age == filterAge
                    } else {
                        ageInRange = project.age <= filterAge
                    }
                    return ageInRange
                })
            } else if (filter.key == 'challenger') {
                datatable = datatable.filter((project) => {
                    let challengerFound = false
                    project.challengers.forEach(challenger => {
                        challengerFound = challengerFound || challenger == filter.value
                    })
                    return challengerFound
                })
            } else if (filter.key == 'terraformVersion') {
                datatable = datatable.filter((project) => {
                    return IacBadgeService.retrieveTerraformVersion(project.allBadges) == filter.value
                })
            } else if (filter.key == 'workspace') {
                datatable = datatable.filter((project) => {
                    let workspaceFound = false
                    IacBadgeService.retrieveWorkspaces(project.allBadges).forEach((workspace: any) => {
                        workspaceFound = workspaceFound || filter.value.includes(workspace)
                    });
                    return workspaceFound
                })
            } else if (filter.key == 'badge') {
                datatable = datatable.filter((project) => {
                    let badgeFound = false
                    IacBadgeService.retrieveTerraformKeyValues(project.allBadges).forEach((badgeKeyValue: any) => {
                        badgeFound = badgeFound || filter.value.includes(badgeKeyValue[0] + "=" + badgeKeyValue[1])
                    });
                    return badgeFound
                })
            } else {
                if (Array.isArray(filter.value)) {
                    datatable = datatable.filter((project) => filter.value.includes(project[filter.key as keyof IacProject]))
                } else {
                    datatable = datatable.filter((project) => project[filter.key as keyof IacProject] === filter.value)
                }
            }
        });
        ctx.patchState({
            datatable: datatable
        })
    }

    @Action(UpdateCountersIacProjects)
    updateCountersIacProjects(ctx: StateContext<IacProjectsStateModel>) {
        const state = ctx.getState()
        let totalItemsCounter = 0
        let compliantItemsCounter = 0
        let notCompliantItemsCounter = 0
        state.datatable.forEach(project => {
            if (project.state == 'compliant') {
                totalItemsCounter++;
                compliantItemsCounter++;
            } else if (project.state == 'not_compliant') {
                totalItemsCounter++;
                notCompliantItemsCounter++;
            }
        });
        ctx.patchState({
            compliantRatio: CompliantRatioService.calculateCompliantRatio(compliantItemsCounter, notCompliantItemsCounter),
            totalItems: '' + totalItemsCounter,
            compliantItems: '' + compliantItemsCounter,
            notCompliantItems: '' + notCompliantItemsCounter
        })
    }

    @Action(ClearIacProject)
    clearIacProject(ctx: StateContext<IacProjectsStateModel>) {
        return ctx.patchState({
            selectedProject: IacProjectsStateModelDefault.selectedProject
        })
    }

    @Action(SetIacProject)
    setIacProject(ctx: StateContext<IacProjectsStateModel>, payload: any) {
        const state = ctx.getState()
        return this.iacProjectsService.loadIacGitlabProjectDetails(payload.payload)
            .pipe(
                tap(value => {
                    return ctx.patchState({
                        selectedProject: value
                    })
                }),
            )
    }

    @Action(LoadIacProjectsCharts)
    loadIacProjectsCharts(ctx: StateContext<IacProjectsStateModel>) {
        const state = ctx.getState()
        return this.iacProjectsService.loadIacGitlabProjectsCharts()
            .pipe(
                tap(value => {
                    return ctx.patchState({
                        chartsData: value
                    })
                }),
            )
    }

    @Action(LoadIacProjectsChartsFilteredData)
    loadIacProjectsChartsFilteredData(ctx: StateContext<IacProjectsStateModel>) {
        let chartsData = ctx.getState().chartsData
        let complianceRatioChartValues: ChartComplianceRatioItem[] = [];
        let complianceTotalChartValues: ChartComplianceTotalItem[] = [];
        let complianceVersusChartValues: ChartVersusItem[] = [];
        let filters = this.store.selectSnapshot(FiltersState.getFiltersByType)[ItemTypeEnum[ItemTypeEnum.IAC_MEASURES]]
        let types: string[] = []
        filters.forEach(filter => {
            types = filter.value
        })
        if (types.length == 0) {
            types = Object.keys(IacProjectTypeEnum)
        }
        for (const dateString in chartsData.counters) {
            const dateMeasures = chartsData.counters[dateString];
            let formattedDate = this.dateService.formatLongDate(dateString)
            let compliantCount = 0
            let notCompliantCount = 0
            for (const type in dateMeasures) {
                if (type != IacProjectTypeEnum[IacProjectTypeEnum.training] && types.includes(type)) {
                    const typeMeasures = dateMeasures[type];
                    compliantCount += typeMeasures.checked;
                    notCompliantCount += typeMeasures.unchecked;
                }
            }
            complianceRatioChartValues.push({
                "dateString": formattedDate,
                "date": new Date(formattedDate),
                "value": CompliantRatioService.calculateCompliantRatio(compliantCount, notCompliantCount)
            });
            complianceTotalChartValues.push({
                "dateString": formattedDate,
                "date": new Date(formattedDate),
                "compliantCount": compliantCount,
                "notCompliantCount": notCompliantCount,
                "total": compliantCount + notCompliantCount,
                "delta": Math.abs(compliantCount - notCompliantCount)
            });
        }
        let lastDate = Object.keys(chartsData.counters)[Object.keys(chartsData.counters).length - 1]
        let lastMeasure = chartsData.counters[lastDate]
        for (const type in lastMeasure) {
            if (type != IacProjectTypeEnum[IacProjectTypeEnum.training] && types.includes(type)) {
                let typeMeasure = lastMeasure[type]
                let colorKey = 'charts_color_' + complianceVersusChartValues.length
                let dateData = {
                    "type": this.iacProjectTypeLabelPipe.transform(type),
                    "count": typeMeasure.checked + typeMeasure.unchecked + typeMeasure.ignored,
                    "color": ColorEnum[colorKey as keyof typeof ColorEnum]
                };
                complianceVersusChartValues.push(dateData);
            }
        }
        return ctx.patchState({
            filteredComplianceRatioChartData: {
                values: complianceRatioChartValues
            },
            filteredComplianceTotalChartData: {
                values: complianceTotalChartValues
            },
            filteredVersusChartData: {
                values: complianceVersusChartValues
            }
        })
    }
}

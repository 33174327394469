import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AwsService {

  constructor() { }

  static readonly ORGANIZATION_ALL = 'ALL ACCOUNTS'
  static readonly ORGANIZATION_EUROPE = 'EUROPE'
  static readonly ORGANIZATION_EUROPE_FINANCE_HR_COMM = 'EUROPE (Finance/HR/Comm)'
  static readonly ORGANIZATION_OTHER = 'OTHER'

  public static listOrganizations() {
    let organizations = [
      {
        name: this.ORGANIZATION_EUROPE,
        accounts: [
          '850062263036',
          '506790841740',
          '609778184017'
        ]
      },
      {
        name: this.ORGANIZATION_EUROPE_FINANCE_HR_COMM,
        accounts: [
          '253490772193',
          '970547343876'
        ]
      },
      {
        name: this.ORGANIZATION_OTHER,
        accounts: [
          '082172913265',
          '793594303043',
          '263506692482',
          '073326253004',
          '931733121667',
          '041796619890',
          '158108687327'
        ]
      }
    ]

    // Add all accounts in an organization ALL
    let allAccounts: string[] = []
    organizations.forEach(organization => {
      organization.accounts.forEach(accountId => {
        allAccounts.push(accountId)
      });
    });
    organizations.push(
      {
        name: this.ORGANIZATION_ALL,
        accounts: allAccounts
      }
    )

    return organizations
  }

  public static getOrganization(accountId: string) {
    let result: string = ''
    this.listOrganizations().forEach(organization => {
      if (organization.name != this.ORGANIZATION_ALL && organization.accounts.includes(accountId)) {
        result = organization.name
      }
    });
    return result
  }

  public static getOrganizationAccounts(organizationName: string) {
    let result: string[] = []
    this.listOrganizations().forEach(organization => {
      if (organization.name == organizationName) {
        result = organization.accounts;
      }
    });
    return result
  }

  public static listAccounts() {
    let result: string[] = []
    this.listOrganizations().forEach(organization => {
      if (organization.name == this.ORGANIZATION_ALL) {
        result = organization.accounts
      }
    });
    return result
  }

  public static orderAccountsMap(accountsMap: Map<string, string>) {
    let orderedAccountsMap: Map<string, string> = new Map<string, string>()
    this.listAccounts().forEach(account => {
      if (accountsMap.has(account)) {
        orderedAccountsMap.set(account, accountsMap.get(account) ?? "")
      }
    });
    return orderedAccountsMap
  }
}

export enum ItemTypeEnum {
    UNKNOWN,
    CICD_PROJECTS,
    CICD_MEASURES,
    IAC_PROJECTS,
    IAC_MEASURES,
    AWS_RESOURCES,
    AWS_MEASURES,
    VSPHERE_RESOURCES,
    VSPHERE_MEASURES,
    IMPERVA_RESOURCES,
    IMPERVA_MEASURES,
    ENTRA_ID_RESOURCES,
    ENTRA_ID_MEASURES,
    GCP_RESOURCES,
    GCP_MEASURES,
    FINOPS_AWS_RESOURCES,
    FINOPS_AWS_RESOURCES_CHARTS,
    FINOPS_AWS_SERVICES,
    FINOPS_AWS_SERVICES_CHARTS,
    FINOPS_AWS_ACCOUNTS,
    FINOPS_AWS_ACCOUNTS_CHARTS,
    ON_OFF_RESOURCES,
    ON_OFF_RESOURCES_CHARTS,
    IAC_TFMODULES,
    CICD_DOCKER_IMAGES,
    CICD_LIBRARIES,
    CICD_TEMPLATES,
    DEVSECOPS_PROJECTS,
    DEVSECOPS_MEASURES
}

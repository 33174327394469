import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "src/app/core/config.service";
import { OnOffResourcesDayMeasures } from "./finops-aws-on-off.model";
import { FinOpsContextConfig } from "../finops-context/finops-context.model";
import { DateService, HistoricalDate } from "src/app/shared/service/date.service";
import { DateGranularityEnum } from "src/app/shared/enum/date-granularity.enum";
import { forkJoin } from "rxjs";
import { AwsService } from "src/app/shared/service/aws.service";

@Injectable({
    providedIn: 'root'
})
export class OnOffResourcesService {

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigService,
        private dateService: DateService
    ) {
    }

    loadOnOffResourcesHistoricalData(config: FinOpsContextConfig, accountId: string) {
        let httpCalls = []
        let historicalDates: HistoricalDate[] = this.dateService.getHistoricalDates(config, 3)
        let accounts: string[] = []
        if (accountId === undefined) {
            accounts = AwsService.listAccounts()
        } else {
            accounts.push(accountId)
        }
        for (let account of accounts) {
            for (let historicalDate of historicalDates) {
                httpCalls.push(this.httpClient.get<OnOffResourcesDayMeasures>(`${this.configService.config.apiUrl}/list-finops-aws-on-off-data?previous_date=${historicalDate.previousDate}&date=${historicalDate.date}&granularity=${DateGranularityEnum[config.granularity]}&account=${account}`))
            }
        }
        return forkJoin(httpCalls)
    }

    loadOnOffResourcesChartsData(config: FinOpsContextConfig, accountId: string) {
        let httpCalls = []
        let accounts: string[] = []
        if (accountId === undefined) {
            accounts = AwsService.listAccounts()
        } else {
            accounts.push(accountId)
        }
        for (let account of accounts) {
            httpCalls.push(this.httpClient.get<OnOffResourcesDayMeasures>(`${this.configService.config.apiUrl}/list-finops-aws-on-off-charts?date=${this.dateService.formatShortDate(config.date)}&account=${account}`))
        }
        return forkJoin(httpCalls)
    }
}

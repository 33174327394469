import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ConfigService } from "src/app/core/config.service";
import { AwsChartsData, AwsResource, AwsResourceDetails, AwsResources } from "./aws-resources.model";
import { AwsResourceTypeEnum } from "../../shared/enum/aws-resource-type.enum";
import { AwsService } from "src/app/shared/service/aws.service";
import { forkJoin } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AwsResourcesService {

    constructor(private httpClient: HttpClient, private configService: ConfigService) {

    }

    loadAwsResources(accountId: string) {
        let httpCalls = []
        let accounts: string[] = []
        if (accountId === undefined) {
            accounts = AwsService.listAccounts()
        } else {
            accounts.push(accountId)
        }
        for (let account of accounts) {
            httpCalls.push(this.httpClient.get<AwsResources>(`${this.configService.config.apiUrl}/list-aws-resources-data?account=${account}`))
        }
        return forkJoin(httpCalls).pipe(
            map((response => {
                const data: any[] = []
                const awsEnumKeys = Object.keys(AwsResourceTypeEnum);
                response.forEach(accountResponse => {
                    for (let type in accountResponse) {
                        const resources = accountResponse[type as keyof AwsResources]
                        if (awsEnumKeys.includes(type)) {
                            data.push(...resources.checked.map((value) => { return { ...value, type: type } }))
                            data.push(...resources.unchecked.map((value) => { return { ...value, type: type } }))
                            data.push(...resources.ignored.map((value) => { return { ...value, type: type } }))
                        }
                    }
                });
                return data
            }))
        )
    }

    loadAwsResourceDetails(resource: any) {
        return this.httpClient.get<AwsResourceDetails>(`${this.configService.config.apiUrl}/get-aws-resource-details?account=${resource.account}&resource_id=${resource.id}`)
    }

    loadAwsCharts(accountId: string) {
        let httpCalls = []
        let accounts: string[] = []
        if (accountId === undefined) {
            accounts = AwsService.listAccounts()
        } else {
            accounts.push(accountId)
        }
        for (let account of accounts) {
            httpCalls.push(this.httpClient.get<AwsChartsData>(`${this.configService.config.apiUrl}/list-aws-charts-data?account=${account}`))
        }
        return forkJoin(httpCalls)
    }
}


import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, StateToken, Store } from "@ngxs/store";
import { AwsResourcesService } from "./aws-resources.service";
import {
    ClearAwsResource,
    LoadAwsResources,
    LoadDataTableAwsResources,
    SetAccount,
    SetOrganization,
    SetAwsResource,
    UpdateCountersAwsResources,
    LoadAwsCharts,
    LoadAwsChartsFilteredData
} from "./aws-resources.actions";
import { map, tap } from "rxjs";
import { AwsResourcesStateModel, AwsResource, AwsChartsData } from "./aws-resources.model";
import { FiltersState } from "../filters/filters.state";
import { CompliantRatioService } from "src/app/shared/service/compliant-ratio.service";
import { AwsService } from "src/app/shared/service/aws.service";
import { ChartComplianceRatioItem } from "src/app/shared/component/chart-compliance-ratio/chart-compliance-ratio.component";
import { ChartComplianceTotalItem } from "src/app/shared/component/chart-compliance-total/chart-compliance-total.component";
import { ChartVersusItem } from "src/app/shared/component/chart-versus/chart-versus.component";
import { AwsResourceTypeEnum } from "src/app/shared/enum/aws-resource-type.enum";
import { DateService } from "src/app/shared/service/date.service";
import { AwsResourceTypeLabelPipe } from "src/app/shared/pipe/aws-resource-type-label.pipe";
import { ColorEnum } from "src/app/shared/enum/color.enum";
import { ItemTypeEnum } from "src/app/shared/enum/item-type.enum";

const IAC_TOKEN: StateToken<AwsResourcesStateModel> = new StateToken('awsResources');

const AwsResourcesStateModelDefaults: AwsResourcesStateModel = {
    resources: [],
    compliantRatio: '0%',
    totalItems: '0',
    compliantItems: '0',
    notCompliantItems: '0',
    datatable: [],
    organizations: AwsService.listOrganizations(),
    selectedAccount: '850062263036',
    selectedOrganization: {
        name: 'europe',
        accounts: AwsService.getOrganizationAccounts(AwsService.ORGANIZATION_EUROPE)
    },
    selectedResource: {
        account: '',
        age: 0,
        id: '',
        cfstacks: [],
        tfstates: [],
        projects: [],
        issues: [],
        name: '',
        region: '',
        source: '',
        state: 'ignored',
        tags: [],
        type: '',
        url: ''
    },
    chartsData: {
        account: '',
        counters: {}
    },
    filteredComplianceRatioChartData: {
        values: []
    },
    filteredComplianceTotalChartData: {
        values: []
    },
    filteredVersusChartData: {
        values: []
    }
}

@State<AwsResourcesStateModel>({
    name: IAC_TOKEN,
    defaults: AwsResourcesStateModelDefaults
})

@Injectable()
export class AwsResourcesState {

    constructor(
        private awsResourcesService: AwsResourcesService,
        private store: Store,
        private dateService: DateService,
        private awsResourceTypeLabelPipe: AwsResourceTypeLabelPipe
    ) {
    }

    @Selector()
    static getOrganizations(state: AwsResourcesStateModel) {
        return state.organizations
    }

    @Selector()
    static getSelectedOrganization(state: AwsResourcesStateModel) {
        return state.selectedOrganization
    }

    @Selector()
    static getResources(state: AwsResourcesStateModel) {
        return state.resources
    }

    @Selector()
    static getSelectedAccount(state: AwsResourcesStateModel) {
        return state.selectedAccount
    }

    @Selector()
    static getCompliantRatio(state: AwsResourcesStateModel) {
        return state.compliantRatio
    }

    @Selector()
    static getTotalItems(state: AwsResourcesStateModel) {
        return state.totalItems
    }

    @Selector()
    static getCompliantItems(state: AwsResourcesStateModel) {
        return state.compliantItems
    }

    @Selector()
    static getNotCompliantItems(state: AwsResourcesStateModel) {
        return state.notCompliantItems
    }

    @Selector()
    static getDatatable(state: AwsResourcesStateModel) {
        return state.datatable
    }

    @Selector()
    static getSelectedResource(state: AwsResourcesStateModel) {
        return state.selectedResource
    }

    @Selector()
    static getFilteredComplianceRatioChartData(state: AwsResourcesStateModel) {
        return state.filteredComplianceRatioChartData
    }

    @Selector()
    static getFilteredComplianceTotalChartData(state: AwsResourcesStateModel) {
        return state.filteredComplianceTotalChartData
    }

    @Selector()
    static getFilteredVersusChartData(state: AwsResourcesStateModel) {
        return state.filteredVersusChartData
    }

    @Action(SetAccount)
    setAccount(ctx: StateContext<AwsResourcesStateModel>, payload: any) {
        const account = ctx.getState().selectedOrganization.accounts.filter(
            account => {
                return account === payload.payload
            }
        )
        return ctx.patchState(
            {
                selectedAccount: account[0]
            }
        )
    }

    @Action(SetOrganization)
    setOrganization(ctx: StateContext<AwsResourcesStateModel>, payload: any) {
        const organization = ctx.getState().organizations.filter(organization => {
            return organization.name === payload.payload
        })
        let selectedAccount = undefined
        if (organization[0].name != AwsService.ORGANIZATION_ALL) {
            selectedAccount = organization[0].accounts[0]
        }
        return ctx.patchState({
            selectedOrganization: organization[0],
            selectedAccount: selectedAccount
        })
    }

    @Action(LoadAwsResources)
    loadAwsResource(ctx: StateContext<AwsResourcesStateModel>) {
        const selectedAccount = ctx.getState().selectedAccount
        return this.awsResourcesService.loadAwsResources(selectedAccount)
            .pipe(
                tap(value => {
                    return ctx.patchState({
                        resources: value
                    })
                }),
            )
    }

    @Action(LoadDataTableAwsResources)
    loadDataTableAwsResources(ctx: StateContext<AwsResourcesStateModel>) {
        const state = ctx.getState()
        let datatable = state.resources

        let filters = this.store.selectSnapshot(FiltersState.getFiltersByType)[ItemTypeEnum[ItemTypeEnum.AWS_RESOURCES]]
        filters.forEach(filter => {
            if (filter.key == 'issuesCount') {
                datatable = datatable.filter((resource) => resource['issues'].length.toString() === filter.value)
            } else if (filter.key == 'issuesType') {
                datatable = datatable.filter((resource) => {
                    let issuesTypeFound = false
                    resource.issues.forEach(issue => {
                        issuesTypeFound = issuesTypeFound || filter.value.includes(issue.issue_type)
                    })
                    return issuesTypeFound
                })
            } else if (filter.key == 'tag') {
                datatable = datatable.filter((resource) => {
                    let tagFound = false
                    Object.entries(resource.tags).forEach((tag: any) => {
                        let tagKey = tag[0]
                        let tagValue = tag[1]
                        filter.value.forEach((filterValue: any) => {
                            let filterTagKey = filterValue.substring(0, filterValue.indexOf("="))
                            let filterTagValue = filterValue.substring(filterValue.indexOf("=") + 1)
                            tagFound = tagFound || (tagKey == filterTagKey && tagValue == filterTagValue)
                        });
                    })
                    return tagFound
                })
            } else if (filter.key == 'age') {
                let unknownAge = 99999
                let filterAge: number = unknownAge
                if (filter.value == 'today') {
                    filterAge = 0
                } else if (filter.value.startsWith('last_')) {
                    filterAge = filter.value.substring(5, 7)
                }
                datatable = datatable.filter((resource) => {
                    let ageInRange = false
                    if (filterAge == unknownAge) {
                        ageInRange = resource.age == filterAge
                    } else {
                        ageInRange = resource.age <= filterAge
                    }
                    return ageInRange
                })
            } else if (filter.key.startsWith('tag_')) {
                let tagKey = filter.key.substring(4)
                if (Array.isArray(filter.value)) {
                    datatable = datatable.filter((resource) => Object.entries(resource['tags']).find(pair => pair[0] === tagKey && filter.value.includes(pair[1])))
                } else {
                    datatable = datatable.filter((resource) => Object.entries(resource['tags']).find(pair => pair[0] === tagKey && pair[1] === filter.value))
                }
            } else {
                if (Array.isArray(filter.value)) {
                    datatable = datatable.filter((resource) => filter.value.includes(resource[filter.key as keyof AwsResource]))
                } else {
                    datatable = datatable.filter((resource) => resource[filter.key as keyof AwsResource] === filter.value)
                }
            }
        });
        ctx.patchState({
            datatable: datatable
        })
    }

    @Action(UpdateCountersAwsResources)
    updateCountersAwsResources(ctx: StateContext<AwsResourcesStateModel>) {
        const state = ctx.getState()
        let totalItemsCounter = 0
        let compliantItemsCounter = 0
        let notCompliantItemsCounter = 0
        state.datatable.forEach(resource => {
            if (resource.state == 'compliant') {
                totalItemsCounter++;
                compliantItemsCounter++;
            } else if (resource.state == 'not_compliant') {
                totalItemsCounter++;
                notCompliantItemsCounter++;
            }
        });
        ctx.patchState({
            compliantRatio: CompliantRatioService.calculateCompliantRatio(compliantItemsCounter, notCompliantItemsCounter),
            totalItems: '' + totalItemsCounter,
            compliantItems: '' + compliantItemsCounter,
            notCompliantItems: '' + notCompliantItemsCounter
        })
    }

    @Action(ClearAwsResource)
    clearAwsResource(ctx: StateContext<AwsResourcesStateModel>) {
        return ctx.patchState({
            selectedResource: AwsResourcesStateModelDefaults.selectedResource
        })
    }

    @Action(SetAwsResource)
    setAwsResource(ctx: StateContext<AwsResourcesStateModel>, payload: any) {
        return this.awsResourcesService.loadAwsResourceDetails(payload.payload)
            .pipe(
                tap(value => {
                    return ctx.patchState({
                        selectedResource: value
                    })
                }),
            )
    }

    @Action(LoadAwsCharts)
    loadAwsCharts(ctx: StateContext<AwsResourcesStateModel>) {
        const selectedOrganization = ctx.getState().selectedOrganization
        const selectedAccount = ctx.getState().selectedAccount
        return this.awsResourcesService.loadAwsCharts(selectedAccount)
            .pipe(
                map((result) => {
                    const chartsData: AwsChartsData = result[0]
                    // If selected organization is ORGANIZATION_ALL, sum each counter
                    if (selectedOrganization.name == AwsService.ORGANIZATION_ALL) {
                        chartsData.account = ''
                        result.forEach((accountResponse, index) => {
                            if (index > 0) {
                                for (let date in accountResponse.counters) {
                                    for (let type in accountResponse.counters[date]) {
                                        if (type != "measure") {
                                            chartsData.counters[date][type].checked += accountResponse.counters[date][type].checked
                                            chartsData.counters[date][type].ignored += accountResponse.counters[date][type].ignored
                                            chartsData.counters[date][type].unchecked += accountResponse.counters[date][type].unchecked
                                        }
                                    }
                                }
                            }
                        });
                    }
                    return ctx.patchState({
                        chartsData: chartsData
                    })
                })
            )
    }

    @Action(LoadAwsChartsFilteredData)
    loadAwsChartsFilteredData(ctx: StateContext<AwsResourcesStateModel>) {
        let chartsData = ctx.getState().chartsData
        let complianceRatioChartValues: ChartComplianceRatioItem[] = [];
        let complianceTotalChartValues: ChartComplianceTotalItem[] = [];
        let complianceVersusChartValues: ChartVersusItem[] = [];
        let filters = this.store.selectSnapshot(FiltersState.getFiltersByType)[ItemTypeEnum[ItemTypeEnum.AWS_MEASURES]]
        let types: string[] = []
        filters.forEach(filter => {
            types = filter.value
        })
        if (types.length == 0) {
            types = Object.keys(AwsResourceTypeEnum)
        }
        for (const dateString in chartsData.counters) {
            const dateMeasures = chartsData.counters[dateString];
            let formattedDate = this.dateService.formatLongDate(dateString)
            let compliantCount = 0
            let notCompliantCount = 0
            for (const type in dateMeasures) {
                if (types.includes(type)) {
                    const typeMeasures = dateMeasures[type];
                    compliantCount += typeMeasures.checked;
                    notCompliantCount += typeMeasures.unchecked;
                }
            }
            complianceRatioChartValues.push({
                "dateString": formattedDate,
                "date": new Date(formattedDate),
                "value": CompliantRatioService.calculateCompliantRatio(compliantCount, notCompliantCount)
            });
            complianceTotalChartValues.push({
                "dateString": formattedDate,
                "date": new Date(formattedDate),
                "compliantCount": compliantCount,
                "notCompliantCount": notCompliantCount,
                "total": compliantCount + notCompliantCount,
                "delta": Math.abs(compliantCount - notCompliantCount)
            });
        }
        let lastDate = Object.keys(chartsData.counters)[Object.keys(chartsData.counters).length - 1]
        let lastMeasure = chartsData.counters[lastDate]
        for (const type in lastMeasure) {
            if (types.includes(type)) {
                let typeMeasure = lastMeasure[type]
                let colorKey = 'charts_color_' + complianceVersusChartValues.length
                let dateData = {
                    "type": this.awsResourceTypeLabelPipe.transform(type),
                    "count": typeMeasure.checked + typeMeasure.unchecked + typeMeasure.ignored,
                    "color": ColorEnum[colorKey as keyof typeof ColorEnum]
                };
                complianceVersusChartValues.push(dateData);
            }
        }
        return ctx.patchState({
            filteredComplianceRatioChartData: {
                values: complianceRatioChartValues
            },
            filteredComplianceTotalChartData: {
                values: complianceTotalChartValues
            },
            filteredVersusChartData: {
                values: complianceVersusChartValues
            }
        })
    }
}

import { Injectable } from '@angular/core';
import { IacBadge, IacProject } from 'src/app/store/iac-projects/iac-projects.model';

@Injectable({
  providedIn: 'root'
})
export class IacBadgeService {

  constructor() { }

  public static retrieveTerraformKeyValues(badges: IacBadge[]): string[][] {
      var terraformBadgesValues: string[][] = [];
      badges.forEach(badge => {
        let terraformIdx = badge['image-url'].indexOf("terraform_")
        if (terraformIdx >= 0) {
          let keyValue: string[] = []
          keyValue[0] = badge['image-url'].substring(terraformIdx, badge['image-url'].indexOf("-", terraformIdx))
          keyValue[1] = badge['image-url'].substring(terraformIdx + keyValue[0].length + 1, badge['image-url'].indexOf("-", terraformIdx + keyValue[0].length + 1))
          terraformBadgesValues.push(keyValue)
        }
      })
      return terraformBadgesValues;
  }

  public static retrieveTerraformVersion(badges: IacBadge[]): string {
      var terraformVersion: string = "";
      this.retrieveTerraformKeyValues(badges).forEach(badgeKeyValue => {
        if(badgeKeyValue[0] == "terraform_version") {
          terraformVersion = badgeKeyValue[1]
        }
      })
      return terraformVersion;
  }

  public static retrieveWorkspaces(badges: IacBadge[]): string[] {
      var workspaces: string[] = [];
      this.retrieveTerraformKeyValues(badges).forEach(badgeKeyValue => {
        if(badgeKeyValue[0] != "terraform_version" && badgeKeyValue[0] != "terraform_module") {
          workspaces.push(badgeKeyValue[0].substring(10))
        }
      })
      return workspaces;
  }
}

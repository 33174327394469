export enum AwsResourceTypeEnum {
  'athena-workgroup',
  'api-gateway',
  'autoscaling',
  'compute-environment',
  'cloudformation-stack',
  'cloudtrail-trail',
  'cognito-identity',
  'cognito-idp-userpool',
  'dms-replication-task',
  'docdb',
  'dynamodb-table',
  'ec2-name',
  'ecr-repository',
  'efs',
  'elasticloadbalancing-listener',
  'elasticloadbalancing-loadbalancer',
  'elasticloadbalancing-targetgroup',
  'glue-job',
  'iam-group',
  'iam-instance-profile',
  'kinesis-stream',
  'kms-alias',
  'lambda',
  'lambda-layer',
  'iam-policy',
  'rds',
  'iam-role',
  's3',
  'sagemaker-notebook-instance',
  'secretsmanager',
  'ec2-security-group',
  'ses-identity',
  'sns',
  'sqs',
  'state-machine',
  'ec2-subnet',
  'iam-user',
  'ec2-vpc',
  'ec2-volume',
  'ecs',
  'ecs-cluster',
  'eks-cluster'
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-devsecops-check',
  templateUrl: './devsecops-check.component.html',
  styleUrls: ['./devsecops-check.component.scss']
})
export class DevsecopsCheckComponent {

  @Input()
  checkImageUrl = ''

  @Input()
  checkLinkUrl = ''
}

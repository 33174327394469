import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, DecimalPipe } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { SidenavComponent } from './component/sidenav/sidenav.component';
import { TileComponent } from './component/tile/tile.component';
import { RouterModule } from '@angular/router';
import { BannerComponent } from './component/banner/banner.component';
import { TranslateModule } from "@ngx-translate/core";
import { RouterLink } from "@angular/router";
import { CardComponent } from './component/card/card.component';
import { NgCircleProgressModule } from "ng-circle-progress";
import { ChallengeColorPipe } from './pipe/challenge-color.pipe';
import { EmergencyColorPipe } from './pipe/emergency-color.pipe';
import { EmergencyLabelPipe } from './pipe/emergency-label.pipe';
import { IssueSolvingPipe } from './pipe/issue-solving.pipe';
import { ButtonComponent } from './component/button/button.component';
import { FilterComponent } from './component/filter/filter.component';
import { AwsResourceTypeLabelPipe } from './pipe/aws-resource-type-label.pipe';
import { IacSourceLabelPipe } from './pipe/iac-source-label.pipe';
import { CompliancyLabelPipe } from './pipe/compliancy-label.pipe';
import { DaysLabelPipe } from './pipe/days-label.pipe';
import { IacProjectTypeLabelPipe } from './pipe/iac-project-type-label.pipe';
import { CicdProjectTypeLabelPipe } from './pipe/cicd-project-type-label.pipe';
import { ArchivedLabelPipe } from './pipe/archived-label.pipe';
import { BadgeComponent } from './component/badge/badge.component';
import { GitlabBadgeComponent } from './component/gitlab-badge/gitlab-badge.component';
import { AwsAccountLabelPipe } from './pipe/aws-account-label.pipe';
import { CompliancyColorPipe } from './pipe/compliancy-color.pipe';
import { ChartComplianceRatioComponent } from './component/chart-compliance-ratio/chart-compliance-ratio.component';
import { ChartComplianceTotalComponent } from './component/chart-compliance-total/chart-compliance-total.component';
import { ChartVersusComponent } from './component/chart-versus/chart-versus.component';
import { GitlabBranchComponent } from './component/gitlab-branch/gitlab-branch.component';
import { IconSuffixNamePipe } from './pipe/icon-suffix-name.pipe';
import { IacSourceColorPipe } from './pipe/iac-source-color.pipe';
import { StatusColorPipe } from './pipe/status-color.pipe';
import { StatusLabelPipe } from './pipe/status-label.pipe';
import { TruncatePipe } from "./pipe/truncate.pipe";
import { AgeLabelPipe } from "./pipe/age-label.pipe";
import { EntraIdTenantLabelPipe } from './pipe/entra-id-tenant-label.pipe';
import { VsphereResourceTypeLabelPipe } from './pipe/vsphere-resource-type-label.pipe';
import { ImpervaResourceTypeLabelPipe } from './pipe/imperva-resource-type-label.pipe';
import { EntraIdResourceTypeLabelPipe } from './pipe/entra-id-resource-type-label.pipe';
import { GcpResourceTypeLabelPipe } from './pipe/gcp-resource-type-label.pipe';
import { FinOpsDateComponent } from './component/finops-date/finops-date.component';
import { DateGranularityLabelPipe } from './pipe/date-granularity-label.pipe';
import { FinOpsConfigComponent } from './component/finops-config/finops-config.component';
import { FinOpsTitleComponent } from './component/finops-title/finops-title.component';
import { FinOpsGapComponent } from './component/finops-gap/finops-gap.component';
import { OnOffDateComponent } from './component/on-off-date/on-off-date.component';
import { OnOffGapComponent } from './component/on-off-gap/on-off-gap.component';
import { ChartFinOpsHistoricalComponent } from './component/chart-finops-historical/chart-finops-historical.component';
import { ChartFinOpsCompareComponent } from './component/chart-finops-compare/chart-finops-compare.component';
import { AwsMonitoringMetricPipe } from './pipe/aws-monitoring-metric.pipe';
import { AwsAccountShortLabelPipe } from './pipe/aws-account-short-label.pipe';
import { CheckComponent } from './component/check/check.component';
import { DevsecopsCheckComponent } from './component/devsecops-check/devsecops-check.component';

@NgModule({
  declarations: [
    SidenavComponent,
    TileComponent,
    BannerComponent,
    CardComponent,
    ChallengeColorPipe,
    EmergencyColorPipe,
    EmergencyLabelPipe,
    IssueSolvingPipe,
    ButtonComponent,
    FilterComponent,
    AwsResourceTypeLabelPipe,
    IacSourceLabelPipe,
    CompliancyLabelPipe,
    DaysLabelPipe,
    TruncatePipe,
    IacProjectTypeLabelPipe,
    CicdProjectTypeLabelPipe,
    ArchivedLabelPipe,
    BadgeComponent,
    GitlabBadgeComponent,
    AwsAccountLabelPipe,
    AwsAccountShortLabelPipe,
    CompliancyColorPipe,
    ChartComplianceRatioComponent,
    ChartComplianceTotalComponent,
    ChartVersusComponent,
    GitlabBranchComponent,
    IconSuffixNamePipe,
    IacSourceColorPipe,
    StatusLabelPipe,
    StatusColorPipe,
    AgeLabelPipe,
    EntraIdTenantLabelPipe,
    VsphereResourceTypeLabelPipe,
    ImpervaResourceTypeLabelPipe,
    EntraIdResourceTypeLabelPipe,
    GcpResourceTypeLabelPipe,
    DateGranularityLabelPipe,
    AwsMonitoringMetricPipe,
    FinOpsConfigComponent,
    FinOpsDateComponent,
    FinOpsTitleComponent,
    FinOpsGapComponent,
    OnOffDateComponent,
    OnOffGapComponent,
    ChartFinOpsHistoricalComponent,
    ChartFinOpsCompareComponent,
    CheckComponent,
    DevsecopsCheckComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TranslateModule,
    RouterLink,
    AsyncPipe,
    // ng-circle-progress from https://www.npmjs.com/package/ng-circle-progress
    NgCircleProgressModule.forRoot({
      backgroundPadding: 2,
      radius: 25,
      space: -4,
      outerStrokeWidth: 4,
      outerStrokeColor: "#808080",
      innerStrokeWidth: 4,
      innerStrokeColor: "#e7e8ea",
      animateTitle: false,
      animationDuration: 1000,
      showSubtitle: false,
      responsive: true,
      renderOnClick: false
    })
  ],
  providers: [
    AwsResourceTypeLabelPipe,
    IacSourceLabelPipe,
    CompliancyLabelPipe,
    CompliancyColorPipe,
    DaysLabelPipe,
    TruncatePipe,
    IacProjectTypeLabelPipe,
    CicdProjectTypeLabelPipe,
    ArchivedLabelPipe,
    AwsAccountLabelPipe,
    AwsAccountShortLabelPipe,
    AgeLabelPipe,
    EntraIdTenantLabelPipe,
    VsphereResourceTypeLabelPipe,
    ImpervaResourceTypeLabelPipe,
    EntraIdResourceTypeLabelPipe,
    GcpResourceTypeLabelPipe,
    DateGranularityLabelPipe,
    DecimalPipe,
    AwsMonitoringMetricPipe
  ],
  exports: [
    SidenavComponent,
    TileComponent,
    CardComponent,
    MaterialModule,
    BannerComponent,
    TranslateModule,
    ChallengeColorPipe,
    EmergencyColorPipe,
    EmergencyLabelPipe,
    IssueSolvingPipe,
    ButtonComponent,
    FilterComponent,
    AwsResourceTypeLabelPipe,
    IacSourceLabelPipe,
    CompliancyLabelPipe,
    DaysLabelPipe,
    TruncatePipe,
    IacProjectTypeLabelPipe,
    CicdProjectTypeLabelPipe,
    ArchivedLabelPipe,
    BadgeComponent,
    GitlabBadgeComponent,
    AwsAccountLabelPipe,
    AwsAccountShortLabelPipe,
    CompliancyColorPipe,
    ChartComplianceRatioComponent,
    ChartComplianceTotalComponent,
    ChartVersusComponent,
    GitlabBranchComponent,
    IconSuffixNamePipe,
    IacSourceColorPipe,
    StatusLabelPipe,
    StatusColorPipe,
    AgeLabelPipe,
    EntraIdTenantLabelPipe,
    VsphereResourceTypeLabelPipe,
    ImpervaResourceTypeLabelPipe,
    EntraIdResourceTypeLabelPipe,
    GcpResourceTypeLabelPipe,
    DateGranularityLabelPipe,
    AwsMonitoringMetricPipe,
    FinOpsConfigComponent,
    FinOpsDateComponent,
    FinOpsTitleComponent,
    FinOpsGapComponent,
    OnOffDateComponent,
    OnOffGapComponent,
    ChartFinOpsHistoricalComponent,
    ChartFinOpsCompareComponent,
    CheckComponent,
    DevsecopsCheckComponent
  ]
})
export class SharedModule { }

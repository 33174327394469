export class LoadDevsecopsProjects {
    static type = 'LoadDevsecopsProjects'
    constructor() { }
}

export class LoadDataTableDevsecopsProjects {
    static type = 'LoadDataTableDevsecopsProjects'
    constructor() { }
}

export class UpdateCountersDevsecopsProjects {
    static type = 'UpdateCountersDevsecopsProjects'
    constructor() { }
}

export class ClearDevsecopsProject {
    static type = 'ClearDevsecopsProject'
    constructor() { }
}

export class SetDevsecopsProject {
    static type = 'SetDevsecopsProject'
    constructor(public payload: any) { }
}

export class LoadDevsecopsProjectsCharts {
    static type = 'LoadDevsecopsProjectsCharts'
    constructor() { }
}

export class LoadDevsecopsProjectsChartsFilteredData {
    static type = 'LoadDevsecopsProjectsChartsFilteredData'
    constructor() { }
}
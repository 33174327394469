import { Pipe, PipeTransform } from '@angular/core';
import { ItemTypeEnum } from '../enum/item-type.enum';
import { TranslateService } from '@ngx-translate/core';

export interface IssueLinkData {
  linkDescription: string,
  linkUrl: string
}

export interface IssueSolvingData {
  itemType: ItemTypeEnum,
  issueType: string,
  description: string,
  howToSolve: string,
  usefulLinks: IssueLinkData[]
}

@Pipe({
  name: 'issueSolving'
})
export class IssueSolvingPipe implements PipeTransform {

  constructor(private translate: TranslateService) {

  }

  readonly ISSUES_SOLVING_DATA: IssueSolvingData[] = [
    // CICD_PROJECTS
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "ALLOW_FAILURE_PYLINT",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.ALLOW_FAILURE_PYLINT.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.ALLOW_FAILURE_PYLINT.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "ALLOW_FAILURE_DAST",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.ALLOW_FAILURE_DAST.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.ALLOW_FAILURE_DAST.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "ALLOW_FAILURE_SAST",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.ALLOW_FAILURE_SAST.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.ALLOW_FAILURE_SAST.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "ALLOW_FAILURE_SONAR",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.ALLOW_FAILURE_SONAR.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.ALLOW_FAILURE_SONAR.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "ALLOW_FAILURE_VERACODE",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.ALLOW_FAILURE_VERACODE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.ALLOW_FAILURE_VERACODE.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "ARCHIVED_CICD_DOCKER_IMAGE_USED",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.ARCHIVED_CICD_DOCKER_IMAGE_USED.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.ARCHIVED_CICD_DOCKER_IMAGE_USED.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "CI/CD docker images",
          linkUrl: "https://gitlab.com/bonduelle-it/devops/cicd/cicd-docker"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "ARCHIVED_CICD_LIBRARY_USED",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.ARCHIVED_CICD_LIBRARY_USED.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.ARCHIVED_CICD_LIBRARY_USED.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "CI/CD libraries",
          linkUrl: "https://gitlab.com/bonduelle-it/devops/cicd/cicd-library"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "ARCHIVED_CICD_TEMPLATE_USED",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.ARCHIVED_CICD_TEMPLATE_USED.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.ARCHIVED_CICD_TEMPLATE_USED.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "CI/CD templates",
          linkUrl: "https://gitlab.com/bonduelle-it/devops/cicd/cicd-template"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "BAD_GIT_FLOW",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.BAD_GIT_FLOW.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.BAD_GIT_FLOW.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "A successful Git branching model",
          linkUrl: "https://nvie.com/posts/a-successful-git-branching-model/"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "BIG_REPOSITORY_SIZE",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.BIG_REPOSITORY_SIZE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.BIG_REPOSITORY_SIZE.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "NO_CICD_TEMPLATE_USED",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.NO_CICD_TEMPLATE_USED.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.NO_CICD_TEMPLATE_USED.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "CI/CD templates",
          linkUrl: "https://gitlab.com/bonduelle-it/devops/cicd/cicd-template"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "NO_LATEST_TAG_FOR_CICD_TEMPLATE",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.NO_LATEST_TAG_FOR_CICD_TEMPLATE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.NO_LATEST_TAG_FOR_CICD_TEMPLATE.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "CI/CD templates",
          linkUrl: "https://gitlab.com/bonduelle-it/devops/cicd/cicd-template"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "NO_SECURITY_TEST_DAST",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.NO_SECURITY_TEST_DAST.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.NO_SECURITY_TEST_DAST.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "CI/CD template DAST",
          linkUrl: "https://gitlab.com/bonduelle-it/devops/cicd/cicd-template/cicd-template-security-dast"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "NO_SECURITY_TEST_SAST",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.NO_SECURITY_TEST_SAST.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.NO_SECURITY_TEST_SAST.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "CI/CD template SAST",
          linkUrl: "https://gitlab.com/bonduelle-it/devops/cicd/cicd-template/cicd-template-security-sast"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "NO_STATIC_ANALYSIS_TEST",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.NO_STATIC_ANALYSIS_TEST.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.NO_STATIC_ANALYSIS_TEST.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "CI/CD template Sonar",
          linkUrl: "https://gitlab.com/bonduelle-it/devops/cicd/cicd-template/cicd-template-sonar-scanner-cli"
        },
        {
          linkDescription: "CI/CD template Python",
          linkUrl: "https://gitlab.com/bonduelle-it/devops/cicd/cicd-template/cicd-template-python"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "SCHEDULED_PIPELINES_WITH_BAD_STATUS",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.SCHEDULED_PIPELINES_WITH_BAD_STATUS.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.SCHEDULED_PIPELINES_WITH_BAD_STATUS.HOW_TO_SOLVE"),
      usefulLinks: [
      ]
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "SCHEDULED_PIPELINES_WITH_RUNNER_ISSUE",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.SCHEDULED_PIPELINES_WITH_RUNNER_ISSUE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.SCHEDULED_PIPELINES_WITH_RUNNER_ISSUE.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "Add a retry in a CI/CD job",
          linkUrl: "https://docs.gitlab.com/ee/ci/yaml/#retry"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "SCHEDULED_PIPELINES_WITHOUT_OWNER",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.SCHEDULED_PIPELINES_WITHOUT_OWNER.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.SCHEDULED_PIPELINES_WITHOUT_OWNER.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "TECHNOLOGY_STACKS_OBSOLESCENCE",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.TECHNOLOGY_STACKS_OBSOLESCENCE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.TECHNOLOGY_STACKS_OBSOLESCENCE.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.CICD_PROJECTS,
      issueType: "TOO_MANY_BRANCHES",
      description: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.TOO_MANY_BRANCHES.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.CICD_PROJECTS.TOO_MANY_BRANCHES.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "A successful Git branching model",
          linkUrl: "https://nvie.com/posts/a-successful-git-branching-model/"
        }
      ]
    },
    // IAC_PROJECTS
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "BAD_GIT_FLOW",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.BAD_GIT_FLOW.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.BAD_GIT_FLOW.HOW_TO_SOLVE"),
      usefulLinks: [
      ]
    },
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "ARCHIVED_MODULE_USED",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.ARCHIVED_MODULE_USED.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.ARCHIVED_MODULE_USED.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "Terraform modules",
          linkUrl: "https://gitlab.com/bonduelle-it/devops/terraform/tf-module-aws"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "ARCHIVED_PROJECT_WITH_RESOURCES",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.ARCHIVED_PROJECT_WITH_RESOURCES.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.ARCHIVED_PROJECT_WITH_RESOURCES.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "BAD_OWNER_TAG",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.BAD_OWNER_TAG.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.BAD_OWNER_TAG.HOW_TO_SOLVE"),
      usefulLinks: [
      ]
    },
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "BAD_PIPELINE_STATUS",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.BAD_PIPELINE_STATUS.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.BAD_PIPELINE_STATUS.HOW_TO_SOLVE"),
      usefulLinks: [
      ]
    },
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "DUPLICATED_TFSTATES",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.DUPLICATED_TFSTATES.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.DUPLICATED_TFSTATES.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "JOB_TO_DESTROY",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.JOB_TO_DESTROY.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.JOB_TO_DESTROY.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "LAMBDA_RUNTIME_OBSOLESCENCE",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.LAMBDA_RUNTIME_OBSOLESCENCE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.LAMBDA_RUNTIME_OBSOLESCENCE.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "MODULE_WITH_RESOURCES",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.MODULE_WITH_RESOURCES.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.MODULE_WITH_RESOURCES.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "NO_LATEST_TAG_FOR_MODULE",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.NO_LATEST_TAG_FOR_MODULE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.NO_LATEST_TAG_FOR_MODULE.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "NO_RESOURCE_FOR_TFSTATE",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.NO_RESOURCE_FOR_TFSTATE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.NO_RESOURCE_FOR_TFSTATE.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "NO_TFSTATE",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.NO_TFSTATE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.NO_TFSTATE.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "OUTDATED_TERRAFORM_VERSION",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.OUTDATED_TERRAFORM_VERSION.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.OUTDATED_TERRAFORM_VERSION.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "CI/CD templates",
          linkUrl: "https://gitlab.com/bonduelle-it/devops/cicd/cicd-template"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "SCHEDULED_PIPELINES_NOT_DAILY_EXEC",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.SCHEDULED_PIPELINES_NOT_DAILY_EXEC.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.SCHEDULED_PIPELINES_NOT_DAILY_EXEC.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "SCHEDULED_PIPELINES_WITH_BAD_STATUS",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.SCHEDULED_PIPELINES_WITH_BAD_STATUS.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.SCHEDULED_PIPELINES_WITH_BAD_STATUS.HOW_TO_SOLVE"),
      usefulLinks: [
      ]
    },
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "SCHEDULED_PIPELINES_WITH_RUNNER_ISSUE",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.SCHEDULED_PIPELINES_WITH_RUNNER_ISSUE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.SCHEDULED_PIPELINES_WITH_RUNNER_ISSUE.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "Add a retry in a CI/CD job",
          linkUrl: "https://docs.gitlab.com/ee/ci/yaml/#retry"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.IAC_PROJECTS,
      issueType: "SCHEDULED_PIPELINES_WITHOUT_OWNER",
      description: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.SCHEDULED_PIPELINES_WITHOUT_OWNER.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.IAC_PROJECTS.SCHEDULED_PIPELINES_WITHOUT_OWNER.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    // AWS_RESOURCES
    {
      itemType: ItemTypeEnum.AWS_RESOURCES,
      issueType: "BAD_TAG_CHARGEBACK",
      description: this.translate.instant("ISSUE_SOLVING.AWS_RESOURCES.BAD_TAG_CHARGEBACK.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.AWS_RESOURCES.BAD_TAG_CHARGEBACK.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "Project Generator",
          linkUrl: "https://project-generator.cldprd.bonduelle.com"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.AWS_RESOURCES,
      issueType: "BAD_TAG_IT_DOMAIN",
      description: this.translate.instant("ISSUE_SOLVING.AWS_RESOURCES.BAD_TAG_IT_DOMAIN.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.AWS_RESOURCES.BAD_TAG_IT_DOMAIN.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "Project Generator",
          linkUrl: "https://project-generator.cldprd.bonduelle.com"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.AWS_RESOURCES,
      issueType: "BAD_REGION",
      description: this.translate.instant("ISSUE_SOLVING.AWS_RESOURCES.BAD_REGION.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.AWS_RESOURCES.BAD_REGION.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.AWS_RESOURCES,
      issueType: "LAMBDA_RUNTIME_OBSOLESCENCE",
      description: this.translate.instant("ISSUE_SOLVING.AWS_RESOURCES.LAMBDA_RUNTIME_OBSOLESCENCE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.AWS_RESOURCES.LAMBDA_RUNTIME_OBSOLESCENCE.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.AWS_RESOURCES,
      issueType: "NO_TAG_CHARGEBACK",
      description: this.translate.instant("ISSUE_SOLVING.AWS_RESOURCES.NO_TAG_CHARGEBACK.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.AWS_RESOURCES.NO_TAG_CHARGEBACK.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "Project Generator",
          linkUrl: "https://project-generator.cldprd.bonduelle.com"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.AWS_RESOURCES,
      issueType: "NO_TAG_IT_DOMAIN",
      description: this.translate.instant("ISSUE_SOLVING.AWS_RESOURCES.NO_TAG_IT_DOMAIN.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.AWS_RESOURCES.NO_TAG_IT_DOMAIN.HOW_TO_SOLVE"),
      usefulLinks: [
        {
          linkDescription: "Project Generator",
          linkUrl: "https://project-generator.cldprd.bonduelle.com"
        }
      ]
    },
    {
      itemType: ItemTypeEnum.AWS_RESOURCES,
      issueType: "NO_PROJECT_FOR_TFSTATE",
      description: this.translate.instant("ISSUE_SOLVING.AWS_RESOURCES.NO_PROJECT_FOR_TFSTATE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.AWS_RESOURCES.NO_PROJECT_FOR_TFSTATE.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.AWS_RESOURCES,
      issueType: "UNUSED_RESOURCE",
      description: this.translate.instant("ISSUE_SOLVING.AWS_RESOURCES.UNUSED_RESOURCE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.AWS_RESOURCES.UNUSED_RESOURCE.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    // VSPHERE_RESOURCES
    {
      itemType: ItemTypeEnum.VSPHERE_RESOURCES,
      issueType: "NO_PROJECT_FOR_TFSTATE",
      description: this.translate.instant("ISSUE_SOLVING.VSPHERE_RESOURCES.NO_PROJECT_FOR_TFSTATE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.VSPHERE_RESOURCES.NO_PROJECT_FOR_TFSTATE.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    // GCP_RESOURCES
    {
      itemType: ItemTypeEnum.GCP_RESOURCES,
      issueType: "NO_PROJECT_FOR_TFSTATE",
      description: this.translate.instant("ISSUE_SOLVING.VSPHERE_RESOURCES.NO_PROJECT_FOR_TFSTATE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.VSPHERE_RESOURCES.NO_PROJECT_FOR_TFSTATE.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    // ENTRA_ID_RESOURCES
    {
      itemType: ItemTypeEnum.ENTRA_ID_RESOURCES,
      issueType: "NO_PROJECT_FOR_TFSTATE",
      description: this.translate.instant("ISSUE_SOLVING.VSPHERE_RESOURCES.NO_PROJECT_FOR_TFSTATE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.VSPHERE_RESOURCES.NO_PROJECT_FOR_TFSTATE.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    // IMPERVA_RESOURCES
    {
      itemType: ItemTypeEnum.IMPERVA_RESOURCES,
      issueType: "NO_PROJECT_FOR_TFSTATE",
      description: this.translate.instant("ISSUE_SOLVING.VSPHERE_RESOURCES.NO_PROJECT_FOR_TFSTATE.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.VSPHERE_RESOURCES.NO_PROJECT_FOR_TFSTATE.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    // FINOPS_AWS_RESOURCES
    {
      itemType: ItemTypeEnum.FINOPS_AWS_RESOURCES,
      issueType: "UNDERSIZED_CPU",
      description: this.translate.instant("ISSUE_SOLVING.FINOPS_AWS_RESOURCES.UNDERSIZED_CPU.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.FINOPS_AWS_RESOURCES.UNDERSIZED_CPU.HOW_TO_SOLVE"),
      usefulLinks: []
    },
    {
      itemType: ItemTypeEnum.FINOPS_AWS_RESOURCES,
      issueType: "OVERSIZED_CPU",
      description: this.translate.instant("ISSUE_SOLVING.FINOPS_AWS_RESOURCES.OVERSIZED_CPU.DESCRIPTION"),
      howToSolve: this.translate.instant("ISSUE_SOLVING.FINOPS_AWS_RESOURCES.OVERSIZED_CPU.HOW_TO_SOLVE"),
      usefulLinks: []
    }
  ]

  transform(itemType: ItemTypeEnum, issueType: string): IssueSolvingData {
    let result: any = null
    this.ISSUES_SOLVING_DATA.forEach(item => {
      if (item.itemType == itemType) {
        if (item.issueType == issueType) {
          result = item
        }
      }
    })
    return result
  }
}

import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, StateToken, Store } from "@ngxs/store";
import { CicdProjectsService } from "./cicd-projects.service";
import {
    ClearCicdProject,
    LoadCicdProjects,
    LoadCicdProjectsCharts,
    LoadCicdProjectsChartsFilteredData,
    LoadDataTableCicdProjects,
    SetCicdProject,
    UpdateCountersCicdProjects
} from "./cicd-projects.actions";
import { tap } from "rxjs";
import { CicdProjectsStateModel, CicdProject } from "./cicd-projects.model";
import { FiltersState } from "../filters/filters.state";
import { CompliantRatioService } from "src/app/shared/service/compliant-ratio.service";
import { ChartComplianceRatioItem } from "src/app/shared/component/chart-compliance-ratio/chart-compliance-ratio.component";
import { CicdProjectTypeEnum } from "src/app/shared/enum/cicd-project-type.enum";
import { DateService } from "src/app/shared/service/date.service";
import { ChartComplianceTotalItem } from "src/app/shared/component/chart-compliance-total/chart-compliance-total.component";
import { ChartVersusItem } from "src/app/shared/component/chart-versus/chart-versus.component";
import { CicdProjectTypeLabelPipe } from "src/app/shared/pipe/cicd-project-type-label.pipe";
import { ColorEnum } from "src/app/shared/enum/color.enum";
import { ItemTypeEnum } from "src/app/shared/enum/item-type.enum";

const CICD_TOKEN: StateToken<CicdProjectsStateModel> = new StateToken('cicdProjects');

const CicdProjectsStateModelDefault: CicdProjectsStateModel = {
    projects: [],
    compliantRatio: '0%',
    totalItems: '0',
    compliantItems: '0',
    notCompliantItems: '0',
    datatable: [],
    selectedProject: {
        age: 0,
        id: '',
        issues: [],
        name: '',
        state: 'ignored',
        branches: [],
        challengers: [],
        archived: false,
        type: '',
        url: '',
        statistics: {
            "active_branches_count": "",
            "commit_count": "",
            "wiki_size": "",
            "packages_size": "",
            "inactive_branches_count": "",
            "lfs_objects_size": "",
            "job_artifacts_size": "",
            "repository_size": "",
            "storage_size": "",
            "uploads_size": "",
            "snippets_size": "",
            "pipeline_artifacts_size": ""
        },
        "technology_stacks": [],
        "cicd-docker-images": [],
        "cicd-libraries": [],
        "cicd-templates": []
    },
    chartsData: {
        counters: {}
    },
    filteredComplianceRatioChartData: {
        values: []
    },
    filteredComplianceTotalChartData: {
        values: []
    },
    filteredVersusChartData: {
        values: []
    }
}


@State<CicdProjectsStateModel>({
    name: CICD_TOKEN,
    defaults: CicdProjectsStateModelDefault
})
@Injectable()
export class CicdProjectsState {

    constructor(
        private cicdProjectsService: CicdProjectsService,
        private store: Store,
        private dateService: DateService,
        private cicdProjectTypeLabelPipe: CicdProjectTypeLabelPipe
    ) {
    }

    @Selector()
    static getProjects(state: CicdProjectsStateModel) {
        return state.projects
    }

    @Selector()
    static getCompliantRatio(state: CicdProjectsStateModel) {
        return state.compliantRatio
    }

    @Selector()
    static getTotalItems(state: CicdProjectsStateModel) {
        return state.totalItems
    }

    @Selector()
    static getCompliantItems(state: CicdProjectsStateModel) {
        return state.compliantItems
    }

    @Selector()
    static getNotCompliantItems(state: CicdProjectsStateModel) {
        return state.notCompliantItems
    }

    @Selector()
    static getDatatable(state: CicdProjectsStateModel) {
        return state.datatable
    }

    @Selector()
    static getSelectedProject(state: CicdProjectsStateModel) {
        return state.selectedProject
    }

    @Selector()
    static getFilteredComplianceRatioChartData(state: CicdProjectsStateModel) {
        return state.filteredComplianceRatioChartData
    }

    @Selector()
    static getFilteredComplianceTotalChartData(state: CicdProjectsStateModel) {
        return state.filteredComplianceTotalChartData
    }

    @Selector()
    static getFilteredVersusChartData(state: CicdProjectsStateModel) {
        return state.filteredVersusChartData
    }

    @Action(LoadCicdProjects)
    loadCicdProjects(ctx: StateContext<CicdProjectsStateModel>) {
        return this.cicdProjectsService.loadCicdGitlabProjects()
            .pipe(
                tap(value => {
                    return ctx.patchState({
                        projects: value
                    })
                }),
            )
    }

    @Action(LoadDataTableCicdProjects)
    loadDataTableCicdProjects(ctx: StateContext<CicdProjectsStateModel>) {
        const state = ctx.getState()
        let datatable = state.projects

        let filters = this.store.selectSnapshot(FiltersState.getFiltersByType)[ItemTypeEnum[ItemTypeEnum.CICD_PROJECTS]]
        filters.forEach(filter => {
            if (filter.key == 'technologyStack') {
                datatable = datatable.filter((project) => {
                    let technologyStackFound = false
                    project.technology_stacks.forEach((technologyStack: string) => {
                        technologyStackFound = technologyStackFound || filter.value.includes(technologyStack)
                    })
                    return technologyStackFound
                })
            } else if (filter.key == 'issuesCount') {
                datatable = datatable.filter((project) => project['issues'].length.toString() === filter.value)
            } else if (filter.key == 'issuesType') {
                datatable = datatable.filter((project) => {
                    let issuesTypeFound = false
                    project.issues.forEach(issue => {
                        issuesTypeFound = issuesTypeFound || filter.value.includes(issue.issue_type)
                    })
                    return issuesTypeFound
                })
            } else if (filter.key == 'age') {
                let unknownAge = 99999
                let filterAge: number = unknownAge
                if (filter.value == 'today') {
                    filterAge = 0
                } else if (filter.value.startsWith('last_')) {
                    filterAge = filter.value.substring(5, 7)
                }
                datatable = datatable.filter((project) => {
                    let ageInRange = false
                    if (filterAge == unknownAge) {
                        ageInRange = project.age == filterAge
                    } else {
                        ageInRange = project.age <= filterAge
                    }
                    return ageInRange
                })
            } else if (filter.key == 'challenger') {
                datatable = datatable.filter((project) => {
                    let challengerFound = false
                    project.challengers.forEach(challenger => {
                        challengerFound = challengerFound || challenger == filter.value
                    })
                    return challengerFound
                })
            } else {
                if (Array.isArray(filter.value)) {
                    datatable = datatable.filter((project) => filter.value.includes(project[filter.key as keyof CicdProject]))
                } else {
                    datatable = datatable.filter((project) => project[filter.key as keyof CicdProject] === filter.value)
                }
            }
        });
        ctx.patchState({
            datatable: datatable
        })
    }

    @Action(UpdateCountersCicdProjects)
    updateCountersCicdProjects(ctx: StateContext<CicdProjectsStateModel>) {
        const state = ctx.getState()
        let totalItemsCounter = 0
        let compliantItemsCounter = 0
        let notCompliantItemsCounter = 0
        state.datatable.forEach(project => {
            if (project.state == 'compliant') {
                totalItemsCounter++;
                compliantItemsCounter++;
            } else if (project.state == 'not_compliant') {
                totalItemsCounter++;
                notCompliantItemsCounter++;
            }
        });
        ctx.patchState({
            compliantRatio: CompliantRatioService.calculateCompliantRatio(compliantItemsCounter, notCompliantItemsCounter),
            totalItems: '' + totalItemsCounter,
            compliantItems: '' + compliantItemsCounter,
            notCompliantItems: '' + notCompliantItemsCounter
        })
    }

    @Action(ClearCicdProject)
    clearCicdProject(ctx: StateContext<CicdProjectsStateModel>) {
        return ctx.patchState({
            selectedProject: CicdProjectsStateModelDefault.selectedProject
        })
    }

    @Action(SetCicdProject)
    setCicdProject(ctx: StateContext<CicdProjectsStateModel>, payload: any) {
        const state = ctx.getState()
        return this.cicdProjectsService.loadCicdGitlabProjectDetails(payload.payload)
            .pipe(
                tap(value => {
                    return ctx.patchState({
                        selectedProject: value
                    })
                }),
            )
    }

    @Action(LoadCicdProjectsCharts)
    loadCicdProjectsCharts(ctx: StateContext<CicdProjectsStateModel>) {
        return this.cicdProjectsService.loadCicdGitlabProjectsCharts()
            .pipe(
                tap(value => {
                    return ctx.patchState({
                        chartsData: value
                    })
                }),
            )
    }

    @Action(LoadCicdProjectsChartsFilteredData)
    loadCicdProjectsChartsFilteredData(ctx: StateContext<CicdProjectsStateModel>) {
        let chartsData = ctx.getState().chartsData
        let complianceRatioChartValues: ChartComplianceRatioItem[] = [];
        let complianceTotalChartValues: ChartComplianceTotalItem[] = [];
        let complianceVersusChartValues: ChartVersusItem[] = [];
        let filters = this.store.selectSnapshot(FiltersState.getFiltersByType)[ItemTypeEnum[ItemTypeEnum.CICD_MEASURES]]
        let types: string[] = []
        filters.forEach(filter => {
            types = filter.value
        })
        if (types.length == 0) {
            types = Object.keys(CicdProjectTypeEnum)
        }
        for (const dateString in chartsData.counters) {
            const dateMeasures = chartsData.counters[dateString];
            let formattedDate = this.dateService.formatLongDate(dateString)
            let compliantCount = 0
            let notCompliantCount = 0
            for (const type in dateMeasures) {
                if (type != CicdProjectTypeEnum[CicdProjectTypeEnum.training] && types.includes(type)) {
                    const typeMeasures = dateMeasures[type];
                    compliantCount += typeMeasures.compliant;
                    notCompliantCount += typeMeasures.not_compliant;
                }
            }
            complianceRatioChartValues.push({
                "dateString": formattedDate,
                "date": new Date(formattedDate),
                "value": CompliantRatioService.calculateCompliantRatio(compliantCount, notCompliantCount)
            });
            complianceTotalChartValues.push({
                "dateString": formattedDate,
                "date": new Date(formattedDate),
                "compliantCount": compliantCount,
                "notCompliantCount": notCompliantCount,
                "total": compliantCount + notCompliantCount,
                "delta": Math.abs(compliantCount - notCompliantCount)
            });
        }
        let lastDate = Object.keys(chartsData.counters)[Object.keys(chartsData.counters).length - 1]
        let lastMeasure = chartsData.counters[lastDate]
        for (const type in lastMeasure) {
            if (type != CicdProjectTypeEnum[CicdProjectTypeEnum.training] && types.includes(type)) {
                let typeMeasure = lastMeasure[type]
                let colorKey = 'charts_color_' + complianceVersusChartValues.length
                let dateData = {
                    "type": this.cicdProjectTypeLabelPipe.transform(type),
                    "count": typeMeasure.compliant + typeMeasure.not_compliant + typeMeasure.ignored,
                    "color": ColorEnum[colorKey as keyof typeof ColorEnum]
                };
                complianceVersusChartValues.push(dateData);
            }
        }
        return ctx.patchState({
            filteredComplianceRatioChartData: {
                values: complianceRatioChartValues
            },
            filteredComplianceTotalChartData: {
                values: complianceTotalChartValues
            },
            filteredVersusChartData: {
                values: complianceVersusChartValues
            }
        })
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'awsAccountShortLabel'
})
export class AwsAccountShortLabelPipe implements PipeTransform {

  constructor(private translate: TranslateService) {

  }

  transform(account: string): string {
    return this.translate.instant("AWS_RESOURCES.ACCOUNT_LABEL." + account + ".SHORT")
  }
}

import { Injectable } from '@angular/core';
import { DevsecopsCheck, DevsecopsProject } from 'src/app/store/devsecops-projects/devsecops-projects.model';

@Injectable({
  providedIn: 'root'
})
export class DevsecopsCheckService {

  constructor() { }

  public static retrieveCheckKeyValues(checks: DevsecopsCheck[]): string[][] {
      var checksValues: string[][] = [];
      checks.forEach(check => {
        let keyValueIdx = check['image-url'].indexOf("badge/") + 6 
        let keyValue: string[] = []
        keyValue[0] = check['image-url'].substring(keyValueIdx, check['image-url'].indexOf("-", keyValueIdx))
        keyValue[1] = check['image-url'].substring(keyValueIdx + keyValue[0].length + 1, check['image-url'].indexOf("-", keyValueIdx + keyValue[0].length + 1))
        checksValues.push(keyValue)
      })
      return checksValues;
  }  

  public static retrieveTerraformKeyValues(checks: DevsecopsCheck[]): string[][] {
      var terraformChecksValues: string[][] = [];
      checks.forEach(check => {
        let terraformIdx = check['image-url'].indexOf("terraform_")
        if (terraformIdx >= 0) {
          let keyValue: string[] = []
          keyValue[0] = check['image-url'].substring(terraformIdx, check['image-url'].indexOf("-", terraformIdx))
          keyValue[1] = check['image-url'].substring(terraformIdx + keyValue[0].length + 1, check['image-url'].indexOf("-", terraformIdx + keyValue[0].length + 1))
          terraformChecksValues.push(keyValue)
        }
      })
      return terraformChecksValues;
  }

  public static retrieveWorkspaces(checks: DevsecopsCheck[]): string[] {
      var workspaces: string[] = [];
      this.retrieveTerraformKeyValues(checks).forEach(checkKeyValue => {
        if(checkKeyValue[0] != "terraform_module") {
          workspaces.push(checkKeyValue[0].substring(10))
        }
      })
      return workspaces;
  }
}

import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, StateToken, Store } from "@ngxs/store";
import { FinOpsAwsResourcesService } from "./finops-aws-resources.service";
import {
    LoadFinOpsAwsResourcesHistoricalData,
    LoadDataTableFinOpsAwsResources,
    SetFinOpsAwsResource,
    ClearFinOpsAwsResource,
    LoadFinOpsAwsResourcesChartsFilteredData,
    LoadFinOpsAwsResourcesChartsData
} from "./finops-aws-resources.actions";
import { map, tap } from "rxjs";
import { FinOpsContextState } from "../finops-context/finops-context.state";
import { FinOpsAwsResource, FinOpsAwsResourcesDayMeasures, FinOpsAwsResourcesStateModel, FinOpsCounters } from "./finops-aws-resources.model";
import { FiltersState } from "../filters/filters.state";
import { FinOpsChartHistoricalItem } from "src/app/shared/component/chart-finops-historical/chart-finops-historical.component";
import { DateGranularityEnum } from "src/app/shared/enum/date-granularity.enum";
import { FinOpsChartCompareItem } from "src/app/shared/component/chart-finops-compare/chart-finops-compare.component";
import { AwsResourceTypeLabelPipe } from "src/app/shared/pipe/aws-resource-type-label.pipe";
import { DecimalPipe } from "@angular/common";
import { ColorEnum } from "src/app/shared/enum/color.enum";
import { TranslateService } from "@ngx-translate/core";
import { DateService } from "src/app/shared/service/date.service";
import { ItemTypeEnum } from "src/app/shared/enum/item-type.enum";
import { AwsService } from "src/app/shared/service/aws.service";

const FINOPS_TOKEN: StateToken<FinOpsAwsResourcesStateModel> = new StateToken('finOpsAwsResources');

const FinOpsAwsResourcesStateModelDefaults: FinOpsAwsResourcesStateModel = {
    historicalData: {
        measures: {}
    },
    datatable: [],
    chartsData: [],
    filteredHistoricalChartData: {
        minCostRatioString: "",
        minCostValueString: "",
        prediction: false,
        costs: []
    },
    filteredCompareProjectsChartData: {
        minCostRatioString: "",
        minCostValueString: "",
        dateString: "",
        prediction: false,
        costs: []
    },
    filteredCompareTypesChartData: {
        minCostRatioString: "",
        minCostValueString: "",
        dateString: "",
        prediction: false,
        costs: []
    },
    filteredCounters: {},
    selectedResource: {
        account: '',
        age: 0,
        id: '',
        name: '',
        region: '',
        state: 'compliant',
        tags: Object,
        type: '',
        url: '',
        counters: {},
        issues: [],
        hourly_states_measures: {},
        monitoring_measures: {},
        specific_data: {
            instance_name: '',
            instance_type: {
                ID: '',
                CPU: '',
                RAM_MIB: ''
            },
            image_id: '',
            engine: '',
            engine_version: '',
            autoscaling: false,
            spot: false,
            spot_saving_month_cost: '',
            spot_saving_ratio: '',
            price: '',
            best_spot_price: '',
            state: '',
            storage: []
        }
    }
}

@State<FinOpsAwsResourcesStateModel>({
    name: FINOPS_TOKEN,
    defaults: FinOpsAwsResourcesStateModelDefaults
})

@Injectable()
export class FinOpsAwsResourcesState {

    constructor(
        private finOpsAwsResourcesService: FinOpsAwsResourcesService,
        private store: Store,
        private awsResourceTypeLabelPipe: AwsResourceTypeLabelPipe,
        private decimalPipe: DecimalPipe,
        private dateService: DateService,
        private translate: TranslateService
    ) {
    }

    @Selector()
    static getHistoricalData(state: FinOpsAwsResourcesStateModel) {
        return state.historicalData
    }

    @Selector()
    static getDatatable(state: FinOpsAwsResourcesStateModel) {
        return state.datatable
    }

    @Selector()
    static getFilteredCounters(state: FinOpsAwsResourcesStateModel) {
        return state.filteredCounters
    }

    @Selector()
    static getChartsData(state: FinOpsAwsResourcesStateModel) {
        return state.chartsData
    }

    @Selector()
    static getFilteredHistoricalChartData(state: FinOpsAwsResourcesStateModel) {
        return state.filteredHistoricalChartData
    }

    @Selector()
    static getFilteredCompareProjectsChartData(state: FinOpsAwsResourcesStateModel) {
        return state.filteredCompareProjectsChartData
    }

    @Selector()
    static getFilteredCompareTypesChartData(state: FinOpsAwsResourcesStateModel) {
        return state.filteredCompareTypesChartData
    }

    @Selector()
    static getSelectedResource(state: FinOpsAwsResourcesStateModel) {
        return state.selectedResource
    }

    @Action(LoadFinOpsAwsResourcesHistoricalData)
    loadFinOpsAwsResourcesHistoricalData(ctx: StateContext<FinOpsAwsResourcesStateModel>) {
        let config = this.store.selectSnapshot(FinOpsContextState.getConfig)
        let selectedOrganization = this.store.selectSnapshot(FinOpsContextState.getSelectedOrganization)
        let selectedAccount = this.store.selectSnapshot(FinOpsContextState.getSelectedAccount)
        return this.finOpsAwsResourcesService.loadFinOpsAwsResourcesHistoricalData(
            config,
            selectedAccount
        ).pipe(
            map((result) => {
                let resultCount = 0
                let historicalCount = 4
                let measures: Record<string, FinOpsAwsResource[]> = {}
                result.slice(resultCount, resultCount + historicalCount).forEach(dayResult => {
                    measures[dayResult.date] = dayResult.measures
                });
                // If selected organization is ORGANIZATION_ALL, sum each measure
                if (selectedOrganization.name == AwsService.ORGANIZATION_ALL) {
                    resultCount += historicalCount
                    while (result.length >= resultCount + historicalCount) {
                        result.slice(resultCount, resultCount + historicalCount).forEach(dayResult => {
                            measures[dayResult.date] = measures[dayResult.date].concat(dayResult.measures)
                        });
                        resultCount += historicalCount
                    }
                }
                return ctx.patchState({
                    historicalData: {
                        measures: measures
                    }
                })
            })
        )
    }

    @Action(LoadDataTableFinOpsAwsResources)
    loadDataTableFinOpsAwsResources(ctx: StateContext<FinOpsAwsResourcesStateModel>) {
        let historicalData = ctx.getState().historicalData
        let selectedDate = this.store.selectSnapshot(FinOpsContextState.getSelectedDate)
        let filters = this.store.selectSnapshot(FiltersState.getFiltersByType)[ItemTypeEnum[ItemTypeEnum.FINOPS_AWS_RESOURCES]]
        let measuresDatatable: { [id: string]: FinOpsAwsResource[]; } = {}
        let filteredCounters: { [id: string]: Record<string, FinOpsCounters>; } = {}
        let firstMeasureDate = true
        let previousTotalCounters: Record<string, FinOpsCounters> = {}
        Object.keys(historicalData.measures).forEach(measureDate => {
            let datatable: FinOpsAwsResource[] = []
            Object.values(historicalData.measures[measureDate]).forEach(resource => {
                datatable.push(resource)
            });
            filters.forEach(filter => {
                if (filter.key == 'project') {
                    datatable = datatable.filter((resource) => {
                        let projectsFound = false
                        resource.projects.forEach(project => {
                            projectsFound = projectsFound || filter.value.includes(project)
                        })
                        return projectsFound
                    })
                } else if (filter.key == 'issuesCount') {
                    datatable = datatable.filter((resource) => resource['issues'].length.toString() === filter.value)
                } else if (filter.key == 'issuesType') {
                    datatable = datatable.filter((resource) => {
                        let issuesTypeFound = false
                        resource.issues.forEach(issue => {
                            issuesTypeFound = issuesTypeFound || filter.value.includes(issue.issue_type)
                        })
                        return issuesTypeFound
                    })
                } else if (filter.key == 'tag') {
                    datatable = datatable.filter((resource) => {
                        let tagFound = false
                        Object.entries(resource.tags).forEach((tag: any) => {
                            let tagKey = tag[0]
                            let tagValue = tag[1]
                            filter.value.forEach((filterValue: any) => {
                                let filterTagKey = filterValue.substring(0, filterValue.indexOf("="))
                                let filterTagValue = filterValue.substring(filterValue.indexOf("=") + 1)
                                tagFound = tagFound || (tagKey == filterTagKey && tagValue == filterTagValue)
                            });
                        })
                        return tagFound
                    })
                } else if (filter.key == 'age') {
                    let unknownAge = 99999
                    let filterAge: number = unknownAge
                    if (filter.value == 'today') {
                        filterAge = 0
                    } else if (filter.value.startsWith('last_')) {
                        filterAge = filter.value.substring(5, 7)
                    }
                    datatable = datatable.filter((resource) => {
                        let ageInRange = false
                        if (filterAge == unknownAge) {
                            ageInRange = resource.age == filterAge
                        } else {
                            ageInRange = resource.age <= filterAge
                        }
                        return ageInRange
                    })
                } else if (filter.key.startsWith('tag_')) {
                    let tagKey = filter.key.substring(4)
                    if (Array.isArray(filter.value)) {
                        datatable = datatable.filter((resource) => Object.entries(resource['tags']).find(pair => pair[0] === tagKey && filter.value.includes(pair[1])))
                    } else {
                        datatable = datatable.filter((resource) => Object.entries(resource['tags']).find(pair => pair[0] === tagKey && pair[1] === filter.value))
                    }
                } else {
                    if (Array.isArray(filter.value)) {
                        datatable = datatable.filter((resource) => filter.value.includes(resource[filter.key as keyof FinOpsAwsResource]))
                    } else {
                        datatable = datatable.filter((resource) => resource[filter.key as keyof FinOpsAwsResource] === filter.value)
                    }
                }
            });
            measuresDatatable[measureDate] = datatable

            // Update counters for the measure
            let totalCounters: Record<string, FinOpsCounters> = {
                "year": {
                    prediction: false,
                    previous_cost: 0,
                    cost: 0,
                    day_ratio: 0,
                    gap_ratio: 0,
                    gap_value: 0
                },
                "month": {
                    prediction: false,
                    previous_cost: 0,
                    cost: 0,
                    day_ratio: 0,
                    gap_ratio: 0,
                    gap_value: 0
                },
                "day": {
                    prediction: false,
                    previous_cost: 0,
                    cost: 0,
                    day_ratio: 0,
                    gap_ratio: 0,
                    gap_value: 0
                }
            }
            datatable.forEach(row => {
                Object.entries(row.counters).forEach(entries => {
                    let granularity = entries[0]
                    let counters = entries[1]
                    totalCounters[granularity].prediction = counters.prediction
                    // Calculate previous_cost only for the first measure
                    if (firstMeasureDate) {
                        totalCounters[granularity].previous_cost += Number(counters.previous_cost)
                    }
                    totalCounters[granularity].cost += Number(counters.cost)
                    totalCounters[granularity].day_ratio = Number(counters.day_ratio)
                });
            });
            Object.keys(totalCounters).forEach(granularity => {
                // Calculate previous_cost only for the first measure
                if (firstMeasureDate) {
                    totalCounters[granularity].previous_cost = Math.round(totalCounters[granularity].previous_cost * 100) / 100
                } else {
                    totalCounters[granularity].previous_cost = previousTotalCounters[granularity].cost
                }
                totalCounters[granularity].cost = Math.round(totalCounters[granularity].cost * 100) / 100
                totalCounters[granularity].day_ratio = Math.round(totalCounters[granularity].day_ratio * 100) / 100
                let gap_value = totalCounters[granularity].cost - totalCounters[granularity].previous_cost
                totalCounters[granularity].gap_ratio = totalCounters[granularity].previous_cost == 0 ? 100 : Math.round((gap_value / totalCounters[granularity].previous_cost) * 10000) / 100
                totalCounters[granularity].gap_value = Math.round(gap_value * 100) / 100
            });
            previousTotalCounters = totalCounters
            firstMeasureDate = false
            filteredCounters[measureDate] = totalCounters
        });

        ctx.patchState({
            datatable: measuresDatatable[selectedDate],
            filteredCounters: filteredCounters
        })
    }

    @Action(ClearFinOpsAwsResource)
    clearFinOpsAwsResource(ctx: StateContext<FinOpsAwsResourcesStateModel>) {
        return ctx.patchState({
            selectedResource: FinOpsAwsResourcesStateModelDefaults.selectedResource
        })
    }

    @Action(SetFinOpsAwsResource)
    setFinOpsAwsResource(ctx: StateContext<FinOpsAwsResourcesStateModel>, payload: any) {
        let selectedDate = this.store.selectSnapshot(FinOpsContextState.getSelectedDate)
        return this.finOpsAwsResourcesService.loadFinOpsAwsResourceDetails(
            selectedDate,
            payload.payload
        ).pipe(
            tap(value => {
                return ctx.patchState({
                    selectedResource: value
                })
            }),
        )
    }

    @Action(LoadFinOpsAwsResourcesChartsData)
    loadFinOpsAwsResourcesChartsData(ctx: StateContext<FinOpsAwsResourcesStateModel>, payload: any) {
        let config = this.store.selectSnapshot(FinOpsContextState.getConfig)
        let selectedOrganization = this.store.selectSnapshot(FinOpsContextState.getSelectedOrganization)
        let selectedAccount = this.store.selectSnapshot(FinOpsContextState.getSelectedAccount)
        return this.finOpsAwsResourcesService.loadFinOpsAwsResourcesChartsData(
            config,
            selectedAccount
        ).pipe(
            map((result) => {
                let resultCount = 0
                let historicalCount = config.depth + 1
                let chartsData: FinOpsAwsResourcesDayMeasures[] = []
                result.slice(resultCount, resultCount + historicalCount).forEach(dayChartsData => {
                    chartsData.push(dayChartsData)
                });
                // If selected organization is ORGANIZATION_ALL, sum each measure
                if (selectedOrganization.name == AwsService.ORGANIZATION_ALL) {
                    resultCount += historicalCount
                    while (result.length >= resultCount + historicalCount) {
                        result.slice(resultCount, resultCount + historicalCount).forEach((dayChartsData, index) => {
                            chartsData[index].account = ""
                            chartsData[index].measures = chartsData[index].measures.concat(dayChartsData.measures)
                        });
                        resultCount += historicalCount
                    }
                }
                return ctx.patchState({
                    chartsData: chartsData
                })
            })
        )
    }

    @Action(LoadFinOpsAwsResourcesChartsFilteredData)
    loadFinOpsAwsResourcesChartsFilteredData(ctx: StateContext<FinOpsAwsResourcesStateModel>) {
        let chartsData = ctx.getState().chartsData
        let config = this.store.selectSnapshot(FinOpsContextState.getConfig)
        let filters = this.store.selectSnapshot(FiltersState.getFiltersByType)[ItemTypeEnum[ItemTypeEnum.FINOPS_AWS_RESOURCES_CHARTS]]
        let historicalChartMinCostRatio: number = 0.0
        let historicalChartMinCostValue: number = 0.0
        let historicalChartCosts: FinOpsChartHistoricalItem[] = []
        let compareProjectsMinCostRatio: number = 0.01
        let compareProjectsMinCostValue: number = 0.0
        let compareProjectsChartCosts: FinOpsChartCompareItem[] = []
        let compareTypesMinCostRatio: number = 0.01
        let compareTypesMinCostValue: number = 0.0
        let compareTypesChartCosts: FinOpsChartCompareItem[] = []
        let prediction: boolean = false

        chartsData.forEach(measureDate => {
            // Filter resources
            let filteredResources: FinOpsAwsResource[] = []
            measureDate.measures.forEach(measure => {
                filteredResources.push(measure)
            });
            filters.forEach(filter => {
                if (filter.key == 'project') {
                    filteredResources = filteredResources.filter((resource) => {
                        let projectsFound = false
                        resource.projects.forEach(project => {
                            projectsFound = projectsFound || filter.value.includes(project)
                        })
                        return projectsFound
                    })
                } else if (filter.key == 'issuesCount') {
                    filteredResources = filteredResources.filter((resource) => resource['issues'].length.toString() === filter.value)
                } else if (filter.key == 'issuesType') {
                    filteredResources = filteredResources.filter((resource) => {
                        let issuesTypeFound = false
                        resource.issues.forEach(issue => {
                            issuesTypeFound = issuesTypeFound || filter.value.includes(issue.issue_type)
                        })
                        return issuesTypeFound
                    })
                } else if (filter.key == 'tag') {
                    filteredResources = filteredResources.filter((resource) => {
                        let tagFound = false
                        Object.entries(resource.tags).forEach((tag: any) => {
                            let tagKey = tag[0]
                            let tagValue = tag[1]
                            filter.value.forEach((filterValue: any) => {
                                let filterTagKey = filterValue.substring(0, filterValue.indexOf("="))
                                let filterTagValue = filterValue.substring(filterValue.indexOf("=") + 1)
                                tagFound = tagFound || (tagKey == filterTagKey && tagValue == filterTagValue)
                            });
                        })
                        return tagFound
                    })
                } else if (filter.key == 'age') {
                    let unknownAge = 99999
                    let filterAge: number = unknownAge
                    if (filter.value == 'today') {
                        filterAge = 0
                    } else if (filter.value.startsWith('last_')) {
                        filterAge = filter.value.substring(5, 7)
                    }
                    filteredResources = filteredResources.filter((resource) => {
                        let ageInRange = false
                        if (filterAge == unknownAge) {
                            ageInRange = resource.age == filterAge
                        } else {
                            ageInRange = resource.age <= filterAge
                        }
                        return ageInRange
                    })
                } else if (filter.key.startsWith('tag_')) {
                    let tagKey = filter.key.substring(4)
                    if (Array.isArray(filter.value)) {
                        filteredResources = filteredResources.filter((resource) => Object.entries(resource['tags']).find(pair => pair[0] === tagKey && filter.value.includes(pair[1])))
                    } else {
                        filteredResources = filteredResources.filter((resource) => Object.entries(resource['tags']).find(pair => pair[0] === tagKey && pair[1] === filter.value))
                    }
                } else {
                    if (Array.isArray(filter.value)) {
                        filteredResources = filteredResources.filter((resource) => filter.value.includes(resource[filter.key as keyof FinOpsAwsResource]))
                    } else {
                        filteredResources = filteredResources.filter((resource) => resource[filter.key as keyof FinOpsAwsResource] === filter.value)
                    }
                }
            });

            // Compute historical chart data for the current date
            let totalCosts: number = 0
            prediction = false
            filteredResources.forEach(resource => {
                totalCosts += Number(resource.counters[DateGranularityEnum[config.granularity]].cost)
                prediction = resource.counters[DateGranularityEnum[config.granularity]].prediction
            });
            historicalChartCosts.push(
                {
                    date: new Date(measureDate.date),
                    cost: Math.round(totalCosts * 100) / 100,
                    dateString: this.dateService.formatLongDate(measureDate.date),
                    predictionString: prediction ? this.translate.instant("FINOPS.PREDICTION") + "\n\n" : ""
                }
            )

            // Compute compare charts data only for the last date
            if (measureDate == chartsData[chartsData.length - 1]) {
                let totalCostsProjects: number = 0
                let totalCostsByProjects = new Map<string, number>();
                let totalCostsTypes: number = 0
                let totalCostsByTypes = new Map<string, number>();
                filteredResources.forEach(resource => {
                    let resourceCost = Number(resource.counters[DateGranularityEnum[config.granularity]].cost)
                    // Compute the type for each resource's project
                    resource.projects.forEach(project => {
                        let projectCost = totalCostsByProjects.get(project)
                        if (projectCost === undefined) {
                            projectCost = 0
                        }
                        totalCostsProjects += resourceCost
                        projectCost += resourceCost
                        totalCostsByProjects.set(project, projectCost)
                    })

                    // Compute the cost for the resource's type
                    let typeCost = totalCostsByTypes.get(resource.type)
                    if (typeCost === undefined) {
                        typeCost = 0
                    }
                    totalCostsTypes += resourceCost
                    typeCost += resourceCost
                    totalCostsByTypes.set(resource.type, typeCost)
                });

                compareProjectsMinCostValue = totalCostsProjects * compareProjectsMinCostRatio
                totalCostsByProjects.forEach((value: number, key: string) => {
                    if (value > compareProjectsMinCostValue) {
                        let colorKey = 'charts_color_' + compareProjectsChartCosts.length
                        compareProjectsChartCosts.push(
                            {
                                category: key,
                                cost: Math.round(value * 100) / 100,
                                color: ColorEnum[colorKey as keyof typeof ColorEnum]
                            }
                        )
                    }
                });

                compareTypesMinCostValue = totalCostsTypes * compareTypesMinCostRatio
                totalCostsByTypes.forEach((value: number, key: string) => {
                    if (value > compareTypesMinCostValue) {
                        let colorKey = 'charts_color_' + compareTypesChartCosts.length
                        compareTypesChartCosts.push(
                            {
                                category: this.awsResourceTypeLabelPipe.transform(key),
                                cost: Math.round(value * 100) / 100,
                                color: ColorEnum[colorKey as keyof typeof ColorEnum]
                            }
                        )
                    }
                });
            }
        });

        ctx.patchState({
            filteredHistoricalChartData: {
                minCostRatioString: historicalChartMinCostRatio == 0 ? "" : this.decimalPipe.transform(historicalChartMinCostRatio * 100)!,
                minCostValueString: historicalChartMinCostValue == 0 ? "" : this.decimalPipe.transform(historicalChartMinCostValue, "1.2-2")!,
                prediction: prediction,
                costs: historicalChartCosts
            },
            filteredCompareProjectsChartData: {
                minCostRatioString: compareProjectsMinCostRatio == 0 ? "" : this.decimalPipe.transform(compareProjectsMinCostRatio * 100)!,
                minCostValueString: compareProjectsMinCostValue == 0 ? "" : this.decimalPipe.transform(compareProjectsMinCostValue, "1.2-2")!,
                dateString: this.dateService.formatLongDate(chartsData[chartsData.length - 1].date),
                prediction: prediction,
                costs: compareProjectsChartCosts
            },
            filteredCompareTypesChartData: {
                minCostRatioString: compareTypesMinCostRatio == 0 ? "" : this.decimalPipe.transform(compareTypesMinCostRatio * 100)!,
                minCostValueString: compareTypesMinCostValue == 0 ? "" : this.decimalPipe.transform(compareTypesMinCostValue, "1.2-2")!,
                dateString: this.dateService.formatLongDate(chartsData[chartsData.length - 1].date),
                prediction: prediction,
                costs: compareTypesChartCosts
            }
        })
    }
}
